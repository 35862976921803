import { FMCarousel } from '@own/fds-react';
import {
    ActionItemContent,
    ActionItemProps,
} from '../../../models/experiencefragments/action-item';
import { FM_ACTION_ITEMS_TYPE } from '../../../constants';
import { FMActionItemsUtil } from './fm-action-items-util';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { AuthenticationState } from '../../../hooks/use-authentication';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { SyncMapService } from '../../../services/sync-service/sync-map-service';
import FMActionItemYFA from '../../fm-action-items/fm-action-item-yfa/fm-action-item-yfa';
import FMActionItemVHA from '../../fm-action-items/fm-action-item-vha/fm-action-item-vha';
import FMActionItemSync from '../../fm-action-items/fm-action-item-sync/fm-action-item-sync';
import FMActionItemLogin from '../../fm-action-items/fm-action-item-login/fm-action-item-login';
import FMActionItemRecall from '../../fm-action-items/fm-action-item-recall/fm-action-item-recall';
import { ExperienceFragmentModel } from '../../../services/content-service/content-service.interface';
import FMActionItemSyncMap from '../../fm-action-items/fm-action-item-sync-map/fm-action-item-sync-map';
import FMActionItemSoftwareUpdate from '../../fm-action-items/fm-action-item-software-update/fm-action-item-software-update';
import FMActionItemServiceReminder from '../../fm-action-items/fm-action-item-service-reminder/fm-action-item-service-reminder';
import FMActionItemSoftwareUpdateDrawer from '../../fm-action-items/fm-action-item-software-update/fm-action-software-update-drawer';

import './fm-action-items.scss';

interface Props {
    vehicleAttributes: VehicleAttributes;
    authenticationState: AuthenticationState;
    login: any;
    region?: string;
}

export interface FMItemsContent extends ExperienceFragmentModel {
    actionItemsTitle: string;
    actionItemTiles: ActionItemContent[];
    actionRequiredLabel: string;
    actionRecommendedLabel: string;
}

const FMActionItems = (props: Props) => {
    const { authenticationState, vehicleAttributes, region } = props;

    const [fmActionItemsAEMContent] = useExperienceContent<FMItemsContent>(
        'vehicles',
        'action-items',
        'action_items'
    );

    const [carouselstate, setCarouselstate] = useState<Map<string, any>>(
        new Map()
    );

    const [isFMModalVisible, setIsFMModalVisible] = useState<boolean>(false);

    const mmotaCallback = (data: boolean) => {
        setIsFMModalVisible(data);
    };
    const handleClose = () => {
        setIsFMModalVisible(false);
    };

    const useCarouselItems = (
        fmActionItemsAEMContent: FMItemsContent | null
    ): ActionItemProps[] => {
        return useMemo(() => {
            const vehicleTilesArray: ActionItemContent[] = [];

            if (
                fmActionItemsAEMContent &&
                Object.keys(fmActionItemsAEMContent).length > 0
            ) {
                fmActionItemsAEMContent?.actionItemTiles?.forEach(
                    (item: ActionItemContent) => {
                        if (
                            Object.values(FM_ACTION_ITEMS_TYPE).includes(
                                item.type
                            )
                        ) {
                            vehicleTilesArray.push(item);
                        }
                    }
                );
            }

            return vehicleTilesArray.map((item: ActionItemContent) => ({
                requiredLabel: fmActionItemsAEMContent?.actionRequiredLabel,
                recommendedLabel:
                    fmActionItemsAEMContent?.actionRecommendedLabel,
                content: item,
            })) as ActionItemProps[];
        }, [fmActionItemsAEMContent]);
    };
    const aemCarouselItems = useCarouselItems(fmActionItemsAEMContent);

    const carouselRenderedItems = (item: ActionItemProps): ReactNode => {
        const { type } = item.content || {};
        switch (type) {
            case FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP:
                return <FMActionItemRecall {...item} />;
            case FM_ACTION_ITEMS_TYPE.SOFTWARE_UPDATE:
                return (
                    <FMActionItemSoftwareUpdate
                        {...item}
                        mmotaCallback={mmotaCallback}
                    />
                );
            case FM_ACTION_ITEMS_TYPE.VHA:
                return (
                    <FMActionItemVHA
                        {...item}
                        vehicleAttributes={vehicleAttributes}
                    />
                );
            case FM_ACTION_ITEMS_TYPE.YFA:
                return <FMActionItemYFA {...item} />;
            case FM_ACTION_ITEMS_TYPE.SYNC_MAP:
                return <FMActionItemSyncMap {...item} />;
            case FM_ACTION_ITEMS_TYPE.SYNC:
                return <FMActionItemSync {...item} />;
            case FM_ACTION_ITEMS_TYPE.SERVICE_REMINDER:
                return <FMActionItemServiceReminder {...item} />;
            case FM_ACTION_ITEMS_TYPE.LOGIN:
                return (
                    authenticationState !==
                        AuthenticationState.Authenticated && (
                        <FMActionItemLogin
                            {...item}
                            authenticationState={authenticationState}
                            login={props?.login}
                        />
                    )
                );
            default:
                return null;
        }
    };
    useEffect(() => {
        const newCarouselState = new Map();
        const syncMapService = new SyncMapService();
        if (!vehicleAttributes.vin) {
            FMActionItemsUtil.getRecallsAndCspYmm(
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
        }
        if (vehicleAttributes.vin) {
            FMActionItemsUtil.getSyncMapService(
                syncMapService,
                region!,
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
            FMActionItemsUtil.requestSyncMapUpdates(
                syncMapService,
                region!,
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
            FMActionItemsUtil.getRecallsAndCsp(
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
        }
        if (
            authenticationState === AuthenticationState.Authenticated &&
            vehicleAttributes.vin
        ) {
            FMActionItemsUtil.getRecallsAndCsp(
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
            FMActionItemsUtil.getVhaService(
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
            FMActionItemsUtil.getYfaService(
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
            FMActionItemsUtil.getActivePrognosticsV2(
                SyncMapService,
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
            FMActionItemsUtil.getMMOTAStatusService(
                vehicleAttributes,
                newCarouselState,
                setCarouselstate
            );
        }
        return () => {
            setCarouselstate(new Map());
        };
    }, [vehicleAttributes.vin, authenticationState, region]);

    const authItems = aemCarouselItems.filter(item =>
        carouselstate.has(item.content.type)
    );
    const unauthItems = aemCarouselItems.filter(
        item =>
            item.content.type === FM_ACTION_ITEMS_TYPE.LOGIN ||
            (item.content.type === FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP &&
                carouselstate.has(FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP)) ||
            (item.content.type === FM_ACTION_ITEMS_TYPE.SYNC &&
                carouselstate.has(FM_ACTION_ITEMS_TYPE.SYNC))
    );
    const items =
        authenticationState === AuthenticationState.Authenticated
            ? authItems
            : unauthItems;
    return (
        <>
            {isFMModalVisible && (
                <FMActionItemSoftwareUpdateDrawer
                    onClose={handleClose}
                    isVisible={isFMModalVisible}
                    {...(carouselstate.get(FM_ACTION_ITEMS_TYPE.SOFTWARE_UPDATE)
                        ? {
                              mmotaData: carouselstate.get(
                                  FM_ACTION_ITEMS_TYPE.SOFTWARE_UPDATE
                              ),
                          }
                        : {})}
                />
            )}
            {fmActionItemsAEMContent && items?.length > 0 && (
                <section className="fm-carousel-action-items">
                    <h2 className="fm-carousel-action-items__title">
                        {fmActionItemsAEMContent?.actionItemsTitle}
                    </h2>
                    <FMCarousel
                        items={items}
                        render={carouselRenderedItems}
                        isUniMode={items?.length < 4}
                        cardWidth={360}
                    />
                </section>
            )}
        </>
    );
};

export default FMActionItems;
