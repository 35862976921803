import { AemDyfEligibleModelResponse } from '../../../models/experiencefragments/dyf-eligible-model';
import { DYF_ELIGIBLE_YEARS } from '../../../constants';
import ServerContext from '../../../contexts/serverContext';
import { useContext, useEffect, useState } from 'react';
import contentService from '../../../services/content-service/content-service';
import { VehicleType } from '../../../models/experiencefragments/vehicle-type';
import VehicleTypeService from '../../../services/vehicle-type-service/vehicle-type-service';
import { getAemVehicleModelData } from '../../use-aem-vehicle-model-data';
import { StringUtil } from '../../../components/utils/string-util/string-util';
import { XOR } from '../../../components/utils/typescript-util/xor';

export const VEHICLE_TYPE_SUPER_DUTY_NAMES = [
    'f-250',
    'f-350',
    'f-450',
    'superduty',
    'super duty',
];

export interface EligibleModelData {
    modelAlias: string;
    modelDisplayName: string;
    modelName: string;
    modelYear: string;
    modelMake: string;
    modelMakeDisplayName: string;
    vehicleType: VehicleType;
    eligibleGVT?: boolean;
    eligibleLivestream?: boolean;
    eligibleCountdown?: boolean;
    eligibleFeatureGuide?: boolean;
    eligibleTowingCalculator?: boolean;
    hideHTV?: boolean;
    eligibleDylBlacklabel?: boolean;
    eligibleDylTailoredImmersion?: boolean;
}
type EligibleModelQueryArgs = XOR<
    { vin: string },
    { year: number | string; modelAlias: string }
>;

export function useDyfEligibleModel(
    hookArgs: EligibleModelQueryArgs | null,
    lazy = false
): {
    data: EligibleModelData | null;
    loading: boolean;
    executeQuery: (args: EligibleModelQueryArgs) => void;
} {
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
        brand,
        currentRegionCode,
    } = useContext(ServerContext);

    const [modelData, setModelData] = useState<EligibleModelData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchVehicleDataByVin = async (vin: string) => {
        const service = new VehicleTypeService();
        try {
            const res = await service.request(
                vin,
                currentLanguageRegionCode,
                current3LetterCountryCode?.toUpperCase()
            );

            if (
                res.vehicleData?.model &&
                parseInt(res.vehicleData?.year) > 2021 &&
                VEHICLE_TYPE_SUPER_DUTY_NAMES.includes(
                    res.vehicleData?.model.toLowerCase()
                )
            ) {
                return {
                    year: `${res.vehicleData?.year}`,
                    model: 'super-duty',
                };
            }

            const aemResult = await getAemVehicleModelData(
                brand,
                currentRegionCode,
                currentLanguageRegionCode,
                res.vehicleData?.year || '',
                res.vehicleData?.model || '',
                'PTS_MARKET_NAME'
            );

            return {
                year: `${aemResult?.year}` || null,
                model: aemResult?.seoKey || null,
            };
        } catch {
            return { year: null, model: null };
        }
    };

    const fetchEligibleModelData = async (
        queryYear: number,
        queryModel: string | undefined
    ) => {
        try {
            const result = await contentService.getExperience<
                AemDyfEligibleModelResponse
            >(
                `dyf/${queryYear}`,
                'dyf-eligible-models',
                'dyf_eligible_models',
                brand,
                currentRegionCode,
                currentLanguageRegionCode
            );

            if (result.models && result.models?.length > 0) {
                const resultModelData = result.models?.find(
                    obj =>
                        obj.modelAlias.toLowerCase() ===
                        queryModel!.toLowerCase()
                );

                if (resultModelData) {
                    setModelData({
                        ...resultModelData,
                        modelYear: `${queryYear}`,
                        modelMake: brand,
                        modelMakeDisplayName: StringUtil.capitalizeFirstLetter(
                            brand
                        ),
                    });
                }
            }
            setLoading(false);
        } catch {
            console.error('Error fetching eligible model data');
            setLoading(false);
        }
    };

    const executeQuery = async (args: EligibleModelQueryArgs) => {
        setLoading(true);
        let queryYear: string | number | null;
        let queryModel: string | null | undefined;

        if (args.vin) {
            const vehicleResult = await fetchVehicleDataByVin(args.vin);
            queryYear = Number(vehicleResult.year);
            queryModel = vehicleResult.model;
        } else {
            queryYear = Number(args.year);
            queryModel = args.modelAlias;
        }

        if (
            !queryYear ||
            !queryModel ||
            isNaN(queryYear) ||
            !DYF_ELIGIBLE_YEARS.includes(queryYear)
        ) {
            setModelData(null);
            setLoading(false);
            return;
        }

        await fetchEligibleModelData(queryYear, queryModel);
    };

    useEffect(() => {
        if (!lazy && hookArgs) {
            if (!lazy && hookArgs.year && hookArgs.modelAlias && loading) {
                executeQuery({
                    year: Number(hookArgs.year),
                    modelAlias: hookArgs.modelAlias,
                });
            } else if (hookArgs.vin && loading) {
                executeQuery({ vin: hookArgs.vin });
            }
        }
    }, [hookArgs, loading]);

    return { loading, data: modelData, executeQuery };
}
