import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import DOMPurify from 'dompurify';
import serverSideService from '../../../services/server-side-service/server-side-service';
import { SELECTORS } from '../../../constants';

const {
    currentCountryCode,
    currentLanguageCode,
    brand,
} = new AppConfigurationService();

export const disableLanguageLinksforTH = () => {
    document
        .querySelectorAll('.lang-links')
        .forEach(link => link.setAttribute('style', 'visibility: hidden'));
};

export const enableLanguageLinksforTH = () => {
    document
        .querySelectorAll('.lang-links')
        .forEach(link => link.setAttribute('style', 'visibility: visible'));
};

export const disableLanguageLinksforCA = () => {
    document
        .querySelectorAll('.language-dropdown')
        .forEach(link => link.setAttribute('style', 'visibility: hidden'));
    document
        .querySelectorAll('.fgx-language')
        .forEach(link => link.setAttribute('style', 'visibility: hidden'));
};

export const enableLanguageLinksforCA = () => {
    document
        .querySelectorAll('.language-dropdown')
        .forEach(link => link.setAttribute('style', 'visibility: visible'));
    document
        .querySelectorAll('.fgx-language')
        .forEach(link => link.setAttribute('style', 'visibility: visible'));
};

export const lincolnModalRedirectPuertoRico = () => {
    const searchIcon = document.querySelector(
        '#global-ux > div > nav > ul > li:nth-child(2) > a'
    );
    const searchIconMobile = document.querySelector(
        '#global-ux > div > div.mobile-nav-wrapper.visible-xs > nav > section > div > div.search > a'
    );

    currentLanguageCode == 'es'
        ? searchIcon?.setAttribute('href', 'https://www.lincoln.com.pr/search/')
        : searchIcon?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/en/search/'
          );

    searchIcon?.removeAttribute('data-toggle');

    currentLanguageCode == 'es'
        ? searchIconMobile?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/search/'
          )
        : searchIconMobile?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/en/search/'
          );

    searchIconMobile?.removeAttribute('data-toggle');

    const requestQuote = document.querySelector(
        '#footer2 > p:nth-child(1) > a'
    );
    currentLanguageCode == 'es'
        ? requestQuote?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/forms/request-a-quote/'
          )
        : requestQuote?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/en/forms/request-a-quote/'
          );
    requestQuote?.removeAttribute('data-toggle');

    const testDrive = document.querySelector('#footer2 > p:nth-child(2) > a');
    currentLanguageCode == 'es'
        ? testDrive?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/forms/test-drive/'
          )
        : testDrive?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/en/forms/test-drive/'
          );
    testDrive?.removeAttribute('data-toggle');

    const headerRequestQuote = document.querySelector(
        '#menu-1 > div > div > div > div > div > section > ul > li > p:nth-child(1) > a'
    );
    currentLanguageCode == 'es'
        ? headerRequestQuote?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/forms/request-a-quote/'
          )
        : headerRequestQuote?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/en/forms/request-a-quote/'
          );
    headerRequestQuote?.removeAttribute('data-toggle');

    const headerRequestQuoteMobile = document.querySelector(
        '#m-menu-1 > div > ul > li:nth-child(2) > p:nth-child(1) > a'
    );
    currentLanguageCode == 'es'
        ? headerRequestQuoteMobile?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/forms/request-a-quote/'
          )
        : headerRequestQuoteMobile?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/en/forms/request-a-quote/'
          );
    headerRequestQuoteMobile?.removeAttribute('data-toggle');

    const headerTestDrive = document.querySelector(
        '#menu-1 > div > div > div > div > div > section > ul > li > p:nth-child(2) > a'
    );
    currentLanguageCode == 'es'
        ? headerTestDrive?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/forms/test-drive/'
          )
        : headerTestDrive?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/en/forms/test-drive/'
          );
    headerTestDrive?.removeAttribute('data-toggle');

    const headerTestDriveMobile = document.querySelector(
        '#m-menu-1 > div > ul > li:nth-child(2) > p:nth-child(2) > a'
    );
    currentLanguageCode == 'es'
        ? headerTestDriveMobile?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/forms/test-drive/'
          )
        : headerTestDriveMobile?.setAttribute(
              'href',
              'https://www.lincoln.com.pr/en/forms/test-drive/'
          );
    headerTestDriveMobile?.removeAttribute('data-toggle');

    document
        .querySelector('#m-menu-vehicles > div.flex-center > div > a')
        ?.removeAttribute('href');
    document
        .querySelector(' #m-menu-1 > div > div > a ')
        ?.removeAttribute('href');
    document
        .querySelector('#m-menu-2 > div > div > a')
        ?.removeAttribute('href');
};

export const lincolnModalRedirectMexico = () => {
    const cotiza = document.querySelector('#footer2 > p:nth-child(8) > a');
    cotiza?.setAttribute(
        'href',
        'https://www.lincoln.mx/overlay/request-a-quote-home/'
    );
    cotiza?.removeAttribute('data-toggle');

    const pruebaDeManejo = document.querySelector(
        '#footer2 > p:nth-child(9) > a'
    );
    pruebaDeManejo?.setAttribute(
        'href',
        'https://www.lincoln.mx/overlay/request-a-test-drive-home/'
    );
    pruebaDeManejo?.removeAttribute('data-toggle');

    const headercotiza = document.querySelector(
        '#menu-1 > div > div > div > div > div > section > ul > li:nth-child(9) > a'
    );
    headercotiza?.setAttribute(
        'href',
        'https://www.lincoln.mx/overlay/request-a-quote-home/'
    );
    headercotiza?.removeAttribute('data-toggle');

    const headercotizaMobile = document.querySelector(
        '#m-menu-1 > div > ul > li:nth-child(10) > a'
    );
    headercotizaMobile?.setAttribute(
        'href',
        'https://www.lincoln.mx/overlay/request-a-quote-home/'
    );
    headercotizaMobile?.removeAttribute('data-toggle');

    const headerpruebaDeManejo = document.querySelector(
        '#menu-1 > div > div > div > div > div > section > ul > li:nth-child(10) > a'
    );
    headerpruebaDeManejo?.setAttribute(
        'href',
        'https://www.lincoln.mx/overlay/request-a-test-drive-home/'
    );
    headerpruebaDeManejo?.removeAttribute('data-toggle');

    const headerpruebaDeManejoMobile = document.querySelector(
        '#m-menu-1 > div > ul > li:nth-child(11) > a'
    );
    headerpruebaDeManejoMobile?.setAttribute(
        'href',
        'https://www.lincoln.mx/overlay/request-a-test-drive-home/'
    );
    headerpruebaDeManejoMobile?.removeAttribute('data-toggle');

    document
        .querySelector('#m-menu-vehicles > div.flex-center > div > a')
        ?.removeAttribute('href');
    document
        .querySelector('#m-menu-1 > div > div > a')
        ?.removeAttribute('href');
    document
        .querySelector('#m-menu-2 > div > div > a')
        ?.removeAttribute('href');
    document
        .querySelector('#m-menu-3 > div > div > a')
        ?.removeAttribute('href');
};

let redirectUrl = '';

const setHrefLangRedirectUrl = (lang: string, pathSegment: string): string => {
    const hrefLangValue = document.querySelector(
        `link[hreflang=${lang}]`
    ) as HTMLAnchorElement;
    const urlHalf = hrefLangValue
        ? hrefLangValue.href.split(pathSegment)[1]
        : '';

    return hrefLangValue
        ? `${redirectUrl.split(pathSegment)[0]}${pathSegment}${urlHalf}#`
        : `${redirectUrl.split(pathSegment)[0]}/#bbt`;
};

const updateLinks = (selectorName: string, url: string): void => {
    document
        .querySelectorAll(selectorName)
        .forEach(link => link.setAttribute('href', url));
};

const replaceLinkATagCanada = (link: string): void => {
    const toggleListItem = document.querySelector(
        SELECTORS.NA_LANG_LINK_SELECTOR
    );

    if (toggleListItem) {
        const newLink = document.createElement('a');
        newLink.textContent = toggleListItem?.textContent || '';
        newLink.href = link;
        newLink.className = `${SELECTORS.NA_LANG_LINK_SELECTOR} column-link`;
        newLink.target = '_self';
        toggleListItem.parentElement?.appendChild(newLink);
        toggleListItem?.parentElement?.removeChild(toggleListItem);
    }
};
export const countryCodeTH = (curPathName: string): void => {
    const setRedirectUrl = (currentPath: string, newPath: string): string =>
        `${curPathName.replace(currentPath, newPath)}#`;
    if (currentLanguageCode === 'en') {
        if (curPathName.includes('en-th'))
            redirectUrl = setRedirectUrl('en-th', 'th-th');
        if (curPathName.includes('/en/support'))
            redirectUrl = setRedirectUrl('/en/support', '/support');
        if (curPathName.includes('/how-tos/'))
            redirectUrl = setHrefLangRedirectUrl('th-th', '/how-tos/');
    } else if (currentLanguageCode === 'th') {
        if (curPathName.includes('th-th'))
            redirectUrl = setRedirectUrl('th-th', 'en-th');
        if (curPathName.includes('/support'))
            redirectUrl = setRedirectUrl('/support', '/en/support');
        if (curPathName.includes('/how-tos/'))
            redirectUrl = setHrefLangRedirectUrl('en-th', '/how-tos/');
    }
    updateLinks('.lang-links li a', redirectUrl);
};

export const countryCodePR = (curPathName: string): void => {
    const setRedirectUrl = (currentPath: string, newPath: string): string =>
        `${curPathName.replace(currentPath, newPath)}#`;

    if (currentLanguageCode === 'en') {
        if (curPathName.includes('en-pr'))
            redirectUrl = setRedirectUrl('en-pr', 'es-pr');
        if (curPathName.includes('/en/support'))
            redirectUrl = setRedirectUrl('/en/support', '/esp/support');
        if (curPathName.includes('/how-tos/'))
            redirectUrl = setHrefLangRedirectUrl('es-pr', '/how-tos/');
    } else if (currentLanguageCode === 'es') {
        if (curPathName.includes('es-pr'))
            redirectUrl = setRedirectUrl('es-pr', 'en-pr');
        if (curPathName.includes('/esp/support'))
            redirectUrl = setRedirectUrl('/esp/support', '/en/support');
        if (curPathName.includes('/how-tos/'))
            redirectUrl = setHrefLangRedirectUrl('en-pr', '/how-tos/');
    }

    updateLinks('.lang-links li a', redirectUrl);
};

export const countryCodeCa = (curPathName: string): void => {
    if (curPathName.includes('/how-tos/')) {
        if (currentLanguageCode === 'en') {
            redirectUrl = `https://${getCurrentDomain(
                curPathName,
                'fr-ca'
            )}${setHrefLangRedirectUrl('fr-ca', '/how-tos/')}`;
        } else if (currentLanguageCode === 'fr') {
            redirectUrl = `https://${getCurrentDomain(
                curPathName,
                'en-ca'
            )}${setHrefLangRedirectUrl('en-ca', '/how-tos/')}`;
        }
        replaceLinkATagCanada(redirectUrl);
    }
};
const getDataHrefDomain = (): string => {
    const languageHtmlLink = document.querySelectorAll(
        SELECTORS.NA_LANG_LINK_SELECTOR
    );

    return languageHtmlLink?.length > 0
        ? languageHtmlLink[0]
              .getAttribute('data-href')
              ?.replace('/support', '') || ''
        : '';
};
const getCurrentDomain = (curPathName: string, newLangReg: string) => {
    if (!curPathName.includes('support') && serverSideService.isClientSide())
        return `${window.location.host}/${brand}/${newLangReg}`;
    else return `${getDataHrefDomain()}/support`;
};

export const countryCode = (curPathName: string): void => {
    const langSwitch = document.querySelectorAll(
        '.login-bar.navbar-right li a'
    );
    const langSwitchMobile = document.querySelectorAll('.lang a');

    if (currentLanguageCode === 'en') {
        if (curPathName.includes('en-pr'))
            redirectUrl = curPathName.replace('en-pr', 'es-pr');
        if (curPathName.includes('/en/support'))
            redirectUrl = curPathName.replace('/en/support', '/esp/support');
        langSwitch.item(0).setAttribute('href', curPathName);
        langSwitch.item(1).setAttribute('href', redirectUrl);
        langSwitchMobile.item(0).setAttribute('href', curPathName);
        langSwitchMobile.item(1).setAttribute('href', redirectUrl);
    } else {
        if (curPathName.includes('es-pr'))
            redirectUrl = curPathName.replace('es-pr', 'en-pr');
        if (curPathName.includes('/esp/support'))
            redirectUrl = curPathName.replace('/esp/support', '/en/support');
        langSwitch.item(0).setAttribute('href', redirectUrl);
        langSwitch.item(1).setAttribute('href', curPathName);
        langSwitchMobile.item(0).setAttribute('href', redirectUrl);
        langSwitchMobile.item(1).setAttribute('href', curPathName);
    }
};

export const remapBrandSiteLinks = (): void => {
    const curPathName = DOMPurify.sanitize(window.location.pathname);
    const handleCountry = (): void => {
        updateLinks(
            '.close.icon-close2, .icon-reserve.profile-accordion, .cta-button.cta-button-primary, .icon-dotted-menu.profile-accordion, .icon-profile-fds.profile-accordion',
            `${curPathName}#`
        );
    };

    switch (currentCountryCode) {
        case 'th':
            countryCodeTH(curPathName);
            break;
        case 'pr':
            if (brand === 'ford') {
                countryCodePR(curPathName);
            } else {
                countryCode(curPathName);
            }
            break;
        case 'ca':
            countryCodeCa(curPathName);
            break;
        default:
            break;
    }

    handleCountry();
};
