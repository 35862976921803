import { ErrorResponse } from '../../components/sections/owners-osb/osb-utils';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';
import ProfileService from '../profile-service/profile-service';
import ServiceHandler from '../service-handler';

interface ConsentEnrollmentStatus {
    isEnrolled: boolean;
    isConsented: boolean;
}

interface PrivacyPreferences {
    statusCode: 'N' | 'Y';
    devices: {
        deviceType: 'email' | 'Cellular';
        deviceValue: string;
    }[];
    consentName: string;
    pouId: string;
}
export interface UserSelections {
    email: boolean;
    fordWebsite: boolean;
    socialMedia: boolean;
    other: boolean;
}
export interface UserReferral extends UserSelections {
    description?: string;
}
export interface EnrollRequest {
    orderId: string;
    phoneNo: string;
    referral: UserReferral;
}

export interface ManagePrivacyRequest {
    brandCode: string;
    countryCode: string;
    privacyPreferences: PrivacyPreferences[];
}

export interface EnrollWithVINRequest {
    vin: string;
    phoneNumber: string;
    email: string;
    brand: string;
    model: string;
    modelYear: string;
    referral: UserReferral;
    referrer: string;
}

export interface EnrollmentResponse {
    message: string;
}

interface ManagePrivacyResponse {
    error?: ErrorResponse;
}

export interface EligibleResponse {
    enrolled: boolean;
    eligible: boolean;
}

class YfaService {
    public async getConsentEnrollmentStatus(): Promise<
        ConsentEnrollmentStatus
    > {
        return HttpService.get<ConsentEnrollmentStatus>(
            DSL_BASE_URL +
                DSL_API_PATH.YFA_PRIVACY_PREFERENCES +
                '?countryCode=USA',
            true,
            {
                headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            }
        )
            .then(axiosResponse => {
                return axiosResponse.data;
            })
            .catch(err => {
                console.error(
                    'Unable to fetch consents and enrollment status ',
                    err
                );
                return {
                    isEnrolled: false,
                    isConsented: false,
                };
            });
    }
    public async updateEnrollment(request: ManagePrivacyRequest) {
        return HttpService.post<ManagePrivacyResponse>(
            DSL_BASE_URL + DSL_API_PATH.YFA_PRIVACY_PREFERENCES,
            request,
            {
                headers: Object.assign(
                    { 'Content-Type': 'application/json' },
                    HttpService.getAppIdAndAuthTokenRequestHeaders()
                ),
            }
        )
            .then(response => {
                return response.data;
            })
            .catch(err => {
                const errorMessage = 'Error saving privacy preferences';
                console.error(errorMessage, err);
                return {
                    error: {
                        errorMessage,
                    },
                };
            });
    }
    public async isEligible(vin: string): Promise<EligibleResponse> {
        const scacId = (await new ProfileService().retrieveCustomerId())
            .consumer?.id;
        const url = DSL_BASE_URL + DSL_API_PATH.YFA_ELIGIBILITY;
        const headers = {
            ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
            vin,
        };
        const countryCode = ServiceHandler.AppConfigurationService.current3LetterCountryCode.toUpperCase();
        const params = { scacId, countryCode };
        return HttpService.get(url, false, { params, headers }).then(
            response => response.data
        );
    }

    public async enroll(body: EnrollRequest): Promise<any> {
        const url = `${DSL_BASE_URL + DSL_API_PATH.CONSUMER_YFA}/enroll`;
        const headers = HttpService.getAppIdAndAuthTokenRequestHeaders();
        return HttpService.post(url, body, { headers }).then(
            response => response.data
        );
    }
    public async enrollWithVin(
        body: EnrollWithVINRequest
    ): Promise<EnrollmentResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.YFA_ENROLL_WITH_VIN;
        const headers = HttpService.getAppIdAndAuthTokenRequestHeaders();
        return HttpService.post(url, body, { headers }).then(
            response => response.data
        );
    }
    public async unenroll(orderId: string): Promise<any> {
        const url = `${DSL_BASE_URL + DSL_API_PATH.CONSUMER_YFA}/unenroll`;
        const headers = HttpService.getAppIdAndAuthTokenRequestHeaders();
        const body = {
            orderId,
        };
        return HttpService.post(url, body, { headers }).then(
            response => response.data
        );
    }
}

export default YfaService;
