import React, { useEffect, useRef, useState, useContext } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { FdsChevron } from '../../common/chevron/fds-chevron';
import { useOfferCategory } from './hooks/use-offer-category';
import { CaoBanner } from './components/cao-banner-details/cao-banner';
import { CouponsAndOffersBanner } from './components/coupons-and-offers-banner/coupons-and-offers-banner';
import './coupons.scss';
import { useSearchContent } from '../search-bar/hooks/use-search-content';
import { LiveChatSearchBar } from '../search-bar/live-chat-search-bar';
import { Helmet } from 'react-helmet';
import useSeoConfig from '../../../hooks/use-seo-config';
import { useAnalytics } from '../../../hooks/use-analytics';
import {
    DisplayCoupons,
    printcardParse,
} from './components/display-coupons/display-coupons';
import { RewardsApiResponse } from './constants/route-constants';
import { useCaoContent } from './hooks/use-cao-banner-details';
import { useLiveChatHelpCardsContent } from '../tirefinder/hooks/use-livechat-helpcard';
import { CaoOptionalCta } from './components/cao-optional-cta/cao-optional-cta';
import { BreadcrumbsReimagine } from '../breadcrumbs-reimagine/breadcrumbs-reimagine';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import ServerContext from '../..../../../../contexts/serverContext';
import { CouponApiData } from './hooks/use-coupon-data';
import { PageNotFoundView } from '../../../views/page-not-found-view/page-not-found-view';

export let dropdown: any;
export const Coupons = () => {
    const [rewardsContent, setRewardsContent] = useState<RewardsApiResponse>();
    const [pageTitle, setPageTitle] = useState<string>('');
    const [isRouteMatched, setIsRouteMatched] = useState<boolean>(true);
    const context = useContext(ServerContext);
    useEffect(() => {
        CouponApiData(context?.brand)
            .then(couponData => {
                setRewardsContent(couponData);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const searchContent = useSearchContent();
    const helpCardsContent = useLiveChatHelpCardsContent();

    const categoryContent = useOfferCategory();

    const finalRef = useRef<HTMLDivElement>(null);
    const [activeDrawer, setActiveDrawer] = useState(false);
    const [activeLink, setActiveLink] = useState('');
    const location = useLocation();

    const handleClick = () => {
        if (activeDrawer) {
            setActiveDrawer(false);
            dropdown = false;
        } else {
            setActiveDrawer(true);
            dropdown = true;
        }
    };

    useEffect(() => {
        if (activeDrawer) {
            const focusFirst = document.querySelector(
                '.focus0'
            ) as HTMLAnchorElement;
            focusFirst?.focus();
        }
    }, [activeDrawer]);

    const isMobile = ServerSideService.isClientSide()
        ? window?.innerWidth <= 991
        : false;
    const routes = [
        {
            path: '/service-maintenance/coupons-offers-rebates/',
            exact: true,
            component: DisplayCoupons(
                '',
                categoryContent?.name1 as string,
                categoryContent?.image1 as string,
                0,
                rewardsContent
            ),
            displayName: categoryContent?.name1 as string,
            image: categoryContent?.image1,
            filter: '',
            categoryName: 'all-savings',
            position: 0,
        },
        {
            path:
                '/service-maintenance/coupons-offers-rebates/battery-coupons/',
            exact: true,
            component: DisplayCoupons(
                'Battery',
                categoryContent?.name2 as string,
                categoryContent?.image2 as string,
                1,
                rewardsContent
            ),
            displayName: categoryContent?.name2 as string,
            image: categoryContent?.image2,
            filter: 'Battery',
            categoryName: 'batteries',
            position: 1,
        },
        {
            path: '/service-maintenance/coupons-offers-rebates/brake-coupons/',
            exact: true,
            component: DisplayCoupons(
                'Brakes',
                categoryContent?.name3 as string,
                categoryContent?.image3 as string,
                2,
                rewardsContent
            ),
            displayName: categoryContent?.name3 as string,
            image: categoryContent?.image3,
            filter: 'Brakes',
            categoryName: 'brakes',
            position: 2,
        },

        {
            path:
                '/service-maintenance/coupons-offers-rebates/oil-change-and-maintenance-coupons/',
            exact: true,
            component: DisplayCoupons(
                'Works',
                categoryContent?.name4 as string,
                categoryContent?.image4 as string,
                3,
                rewardsContent
            ),
            displayName: categoryContent?.name4 as string,
            image: categoryContent?.image4,
            filter: 'Works',
            categoryName: 'oil-change-and-maintenance',
            position: 3,
        },
        {
            path: '/service-maintenance/coupons-offers-rebates/tire-coupons/',
            exact: true,
            component: DisplayCoupons(
                'Tires',
                categoryContent?.name5 as string,
                categoryContent?.image5 as string,
                4,
                rewardsContent
            ),
            displayName: categoryContent?.name5 as string,
            image: categoryContent?.image5,
            filter: 'Tires',
            categoryName: 'tires',
            position: 4,
        },
        {
            path:
                '/service-maintenance/coupons-offers-rebates/wiper-blade-coupons/',
            exact: true,
            component: DisplayCoupons(
                'Wipers',
                categoryContent?.name6 as string,
                categoryContent?.image6 as string,
                5,
                rewardsContent
            ),
            displayName: categoryContent?.name6 as string,
            image: categoryContent?.image6,
            filter: 'Wipers',
            categoryName: 'wiper-blades',
            position: 5,
        },
    ];
    let currentRoute: any;
    let categoryName = 'all-savings';
    let analyticsCategoryName: any;
    const pathName: string[] = [];
    const categoryPath = ServerSideService.isClientSide()
        ? window.location.href
        : location.pathname + '/';
    routes.forEach(route => {
        if (categoryPath && categoryPath.indexOf(route.path) !== -1) {
            categoryName = route.categoryName;
            analyticsCategoryName = categoryName.split('-').join(' ');
            currentRoute = route;
        }
        const path = route.path.split('/');
        if (path) {
            pathName.push(path[path.length - 2]);
        }
    });

    const couponsCategoryName = {
        couponsCategoryName: analyticsCategoryName,
    };
    useSeoConfig(categoryName, 'coupon');
    useAnalytics(['coupons'], undefined, couponsCategoryName);
    const [fireEvents] = useAnalytics();
    const [tabIndex, setTabIndex] = useState(0);

    const handleChange = () => {
        setTabIndex(tabIndex + 1);
        if (isMobile) {
            const nextFocus = document.querySelector(
                `.nav-title`
            ) as HTMLButtonElement;
            nextFocus?.focus();
        }
    };

    useEffect(() => {
        // Check if the current path is a valid route
        const currentPath = location.pathname;
        const routeExists = routes.some(route => route.path === currentPath);
        setIsRouteMatched(routeExists);
    }, []);

    useEffect(() => {
        const noCoupons = rewardsContent?.coupons.filter(
            coupon =>
                coupon.commodityDescription
                    .split(':')
                    .slice(1)
                    .join(':')
                    .indexOf(currentRoute.filter) !== -1
        );

        setPageTitle(`${currentRoute?.displayName} (${noCoupons?.length})`);
    }, [currentRoute, rewardsContent]);

    useEffect(() => {
        if (!isMobile) {
            const nextIconFocus = document.querySelector(
                `.print-button0`
            ) as HTMLButtonElement;
            document.querySelectorAll(`.print-button`).forEach(value => {
                value.setAttribute('tabIndex', '0');
            });
            document.querySelectorAll(`.tooltip__button`).forEach(value => {
                value.setAttribute('tabIndex', '0');
            });
            document.querySelectorAll(`.view-offer-button`).forEach(value => {
                value.setAttribute('tabIndex', '0');
            });
            nextIconFocus?.focus();
        }
        if (tabIndex > 0) {
            fireEvents(['coupons'], undefined, couponsCategoryName);
        }
    }, [tabIndex]);

    const CaoContent = useCaoContent();

    return (
        <>
            {!isRouteMatched && (
                <>
                    <PageNotFoundView />
                </>
            )}
            {isRouteMatched && (
                <div className="coupons-and-offers">
                    <Helmet>
                        <title>Coupons, Offers & Rebates </title>
                        <meta
                            name="description"
                            content=" Browse all Ford coupons and discounts including tire deals, brake coupons, oil change discounts, and more Save now with Ford battery coupons and service rebates and redeem your offers at your Ford dealer today"
                        ></meta>
                    </Helmet>
                    <div className="rewards-print-only">{printcardParse}</div>
                    <BreadcrumbsReimagine
                        currentPage="All Offers, Rebates & Coupons"
                        type="common"
                    />
                    {CaoContent && (
                        <div>
                            <CouponsAndOffersBanner
                                topComponent={<CaoBanner heading={pathName} />}
                                backgroundImageDesktop={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    CaoContent.caoBackgroundImageDesktop
                                }
                                backgroundImageMobile={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    CaoContent.caoBackgroundImageMobile
                                }
                            />
                        </div>
                    )}
                    {!finalRef.current && (
                        <div className="rewards">
                            <div className="rewards-split">
                                <h2 className="view-category">
                                    View categories :
                                </h2>
                                <div className="left-menu">
                                    <div className="nav-title-wrapper">
                                        <button
                                            className={`nav-title`}
                                            onClick={() => {
                                                handleClick();
                                            }}
                                            aria-label="Click to view categories"
                                            tabIndex={!activeDrawer ? 0 : -1}
                                            aria-expanded={activeDrawer}
                                        >
                                            <div
                                                className={`${
                                                    activeDrawer
                                                        ? 'rewards-active-drawer-open'
                                                        : 'rewards-active-drawer-closed'
                                                }`}
                                            >
                                                <div className="chevron-container">
                                                    <FdsChevron
                                                        type="unfilled"
                                                        direction={
                                                            activeDrawer
                                                                ? 'up'
                                                                : 'down'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </button>
                                        <ul
                                            onClick={() => {
                                                handleClick();
                                            }}
                                            className={`${
                                                activeDrawer
                                                    ? 'dropdown-closed'
                                                    : 'hide-me'
                                            }`}
                                            style={{
                                                listStyleType: 'none',
                                                padding: 0,
                                            }}
                                        >
                                            {routes.map((route, index) => (
                                                <li
                                                    key={route.displayName}
                                                    onBlur={() => {
                                                        if (
                                                            index + 1 ===
                                                            routes.length
                                                        ) {
                                                            handleChange();
                                                        }
                                                    }}
                                                    className={`
                                                ${
                                                    index === 0
                                                        ? 'category-first-item'
                                                        : 'list-item'
                                                } 
                                            `}
                                                >
                                                    <img
                                                        className="playButton"
                                                        src={
                                                            (process.env
                                                                .REACT_APP_AEM_BASE_URL as string) +
                                                            route.image
                                                        }
                                                        alt={`${route.displayName} `}
                                                    />
                                                    <Link
                                                        to={route.path}
                                                        className={`${index} link focus${index}`}
                                                        onClick={() => {
                                                            handleChange();
                                                        }}
                                                        aria-label={`view offers on ${route.displayName}`}
                                                    >
                                                        <>{route.displayName}</>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <ul
                                        className="desktop-only"
                                        style={{
                                            listStyleType: 'none',
                                            padding: 0,
                                        }}
                                    >
                                        {routes.map((route, index) => (
                                            <li key={route.displayName}>
                                                <img
                                                    className="playButton"
                                                    src={
                                                        (process.env
                                                            .REACT_APP_AEM_BASE_URL as string) +
                                                        route.image
                                                    }
                                                    alt={
                                                        route.displayName +
                                                        'category'
                                                    }
                                                />
                                                <Link
                                                    className={`desktop${index} desktop ${
                                                        activeLink ===
                                                        route.displayName
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    to={route.path}
                                                    onClick={() => {
                                                        setActiveLink(
                                                            route.displayName
                                                        );
                                                        handleChange();
                                                    }}
                                                    aria-label={`view offers on ${route.displayName}`}
                                                >
                                                    {`${route.displayName} `}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="rewards-cta-desktop">
                                        <hr aria-hidden="true" />
                                        <CaoOptionalCta
                                            categoryName={categoryName}
                                        />
                                    </div>
                                </div>
                                <div className="coupon-content">
                                    <span
                                        className="mobileCount"
                                        role={'status'}
                                    >
                                        <h2 className="dropdown-selected">
                                            {pageTitle}
                                        </h2>
                                    </span>
                                    <Switch>
                                        {routes.map(route => (
                                            <Route
                                                key={route.displayName}
                                                path={route.path}
                                                exact={route.exact}
                                            >
                                                <>{route.component}</>
                                            </Route>
                                        ))}
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    )}
                    {searchContent && helpCardsContent && (
                        <LiveChatSearchBar
                            searchContent={searchContent}
                            helpCardsContent={helpCardsContent}
                        />
                    )}
                </div>
            )}
        </>
    );
};
Coupons.displayName = 'Coupons';
