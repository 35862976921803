import ServiceHandler from '../service-handler';
import { DSL_API_PATH, DSL_BASE_URL } from '../../constants';
import HttpService from '../http-service/http-service';

export interface VinLookupYM {
    getOwnerInformationByVinResult: {
        modelYear: string;
        model: string;
    };
}

export class VinVehicleService {
    private readonly _vin: string;

    constructor(vin: string) {
        this._vin = vin.length > 0 ? vin.trim().toUpperCase() : '';
    }

    async getYearAndModel(): Promise<VinLookupYM> {
        const {
            current3LetterCountryCode,
        } = ServiceHandler.AppConfigurationService;
        const consumerKeyHeader = HttpService.getAppIdRequestHeaders()[
            'Consumer-Key'
        ];
        const headers = {
            vin: this._vin,
            'Consumer-Key': consumerKeyHeader,
        };
        const url = `${DSL_BASE_URL +
            DSL_API_PATH.OWNERS_MANUAL_VIN_V3}?country=${current3LetterCountryCode.toUpperCase()}`;
        const response = HttpService.get<VinLookupYM>(url, true, {
            headers,
        });
        return response.then(({ data }) => data);
    }
}
