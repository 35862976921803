import { createClient } from 'redis';
import serverSideService from '../server-side-service/server-side-service';
let redisClient: any = null;
let redisClientInterval: NodeJS.Timeout | null = null;

const configureRedisClient = (isGcp: boolean) => {
    if (isGcp) {
        return createClient({
            password: process.env.REACT_APP_REDIS_CERT_PWD,
            socket: {
                host: process.env.REACT_APP_REDIS_HOST,
                port: process.env.REACT_APP_REDIS_PORT,
                tls: true,
                noDelay: true,
                ca: [process.env.REACT_APP_REDIS_CA_CERT],
                reconnectStrategy: retryStrategy,
            },
        });
    } else {
        return createClient({
            socket: {
                reconnectStrategy: retryStrategy,
            },
            port: process.env.REACT_APP_REDIS_PORT,
        });
    }
};

const retryStrategy = (retries: number) => {
    return retries > 20 ? new Error('Too many retries.') : retries * 500;
};

const setupRedisEventHandlers = () => {
    redisClient.on('ready', () => {
        console.log('Redis Client is ready!');
    });

    redisClient.on('reconnecting', () => {
        console.log('Connection lost! Reconnecting to Redis...');
    });

    redisClient.on('error', async (e: any) => {
        console.error('Error connecting to Redis!', e);
        await handleRedisError();
    });
    redisClient.on('close', async (e: any) => {
        console.error('Closing connection to Redis!', e);
        await handleRedisError();
    });
};

const handleRedisError = async () => {
    if (redisClient) {
        try {
            await redisClient.quit();
        } catch (quitError) {
            console.error('Error while quitting Redis client:', quitError);
        }
    }
    redisClient = null;
    if (redisClientInterval) {
        clearInterval(redisClientInterval);
    }
};

// Refactored createRedisClient function
export const createRedisClient = async () => {
    if (serverSideService.isServerSide() && !redisClient) {
        try {
            const isGcp = process.env.REACT_APP_PLATFORM === 'gcp';
            redisClient = configureRedisClient(isGcp);
            await redisClient.connect();
            console.log('New Redis client created');
            redisClientInterval = setInterval(() => {
                redisClient.ping().catch((err: any) => {
                    console.error('Redis ping failed:', err);
                });
            }, 20000);
            setupRedisEventHandlers();
        } catch (error) {
            console.error('Redis client Failed: ', error);
            await handleRedisError();
        }
    }
    if (!serverSideService.isServerSide()) {
        return null;
    }
    return redisClient;
};
