import axios, { AxiosRequestConfig } from 'axios';
import { HttpsProxyAgent } from 'https-proxy-agent';
import HttpService from '../http-service/http-service';
import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export interface VHRScheduleResponse {
    VinResponseList: ScheduleList[];
}

export interface ScheduleList {
    Frequency: string;
    LastPublished: string;
    LastUpdated: string;
    NextScheduled: string;
    Vin: string;
}

export interface VHRUpdateResponse {
    message: string;
    status: number;
    version: string;
}

export default class VHRSheduleService {
    config: AxiosRequestConfig;
    SCHEDULE_URL = `${DSL_BASE_URL + DSL_API_PATH.VHR_SCHEDULE}`;

    constructor() {
        this.config = {
            headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            proxy: false,
            httpAgent: proxyAgent,
        };
    }

    public getVhrSchedule(vin: string): Promise<VHRScheduleResponse> {
        this.config.headers = {
            ...this.config.headers,
            vin: vin,
        };

        return HttpService.get<VHRScheduleResponse>(
            this.SCHEDULE_URL,
            false,
            this.config
        ).then(response => {
            return response.data;
        });
    }

    public updateVhrSchedule(
        frequency: string,
        vin: string
    ): Promise<VHRUpdateResponse> {
        return HttpService.post<VHRUpdateResponse>(
            this.SCHEDULE_URL,
            {
                frequency: frequency,
                vin: vin,
            },
            this.config
        ).then(response => {
            return response.data;
        });
    }

    public deleteVhrSchedule(vin: string): Promise<VHRUpdateResponse> {
        this.config.headers = {
            ...this.config.headers,
            vin: vin,
        };
        return axios
            .delete<VHRUpdateResponse>(this.SCHEDULE_URL, this.config)
            .then(response => {
                return response.data;
            });
    }
}
