import { useContent } from '../../../hooks/use-server-content';

export interface SyncCitivanCheckVersionProps {
    hideContent: boolean;
    title: string;
    description: string;
    vwVersionLabel: string;
}

export const useSyncCitivanCheckVersion = (): SyncCitivanCheckVersionProps | null => {
    const [content, getValueByName] = useContent(
        'sync',
        'sync-citivan-check-version'
    );
    let contentFragment: any = {};
    content?.elements.forEach(element => {
        contentFragment[`${element.name}`] = getValueByName(`${element.name}`);
    });
    if (contentFragment) {
        if (contentFragment.hideContent || !contentFragment.title) {
            contentFragment = null;
        }
    }

    return contentFragment as SyncCitivanCheckVersionProps;
};
