import { useExperienceContent } from '../../../../hooks/use-server-content';
import { ContainerFragment } from '../../../../models/service-maintenance-models/vehicle-health-alerts';

export const useSecondarNavContent = () => {
    const ITEMS = ':items';
    const [secondaryNavContent] = useExperienceContent<ContainerFragment>(
        'secondarynav',
        'secondarynav',
        'container'
    );
    return secondaryNavContent?.[ITEMS];
};
