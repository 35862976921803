import { useDyfEligibleModel } from '../../../hooks/dyf/use-dyf-eligible-model/use-dyf-eligible-model';
import { ReactNode, useContext, useEffect, useMemo } from 'react';
import ServerContext from '../../../contexts/serverContext';
import { useAnalytics } from '../../../hooks/use-analytics';
import {
    DisclaimerContent,
    DisclaimersService,
} from '../../../services/disclaimers-service/disclaimers-service';
import { useDyfQueryParams } from '../../../hooks/dyf/use-dyf-query-params/use-dyf-query-params';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { FMHubFragment } from '../../../models/experiencefragments/fm-hub';
import { FMBannersProps } from './hook/use-fm-banners';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';
import {
    ctaHref,
    handleListItemEvent,
} from '../../quicklinks/quick-links-util';
import { transformRelativeUrlValidateRoot } from '../../utils/router-util/router-util';
import {
    FMListItem,
    FMTabsWithAccordion,
    FMCardFiftyFifty,
} from '@own/fds-react';
import FMListItemDYF from './fm-list-item-dyf/fm-list-item-dyf';
import { DYF_TILE_TYPE } from '../../../constants';
import { QuickLinksContent } from '../../../models/experiencefragments/quick-links';
import { ShortcodeProviders } from '../../../services/shortcode-service/shortcode-service';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';

export function useFMHubComponent(props: VehicleAttributes) {
    const [fmHubContent] = useExperienceContent<FMHubFragment>(
        'vehicles',
        getVehicleDataSelector('hub', props),
        'fm_hub',
        undefined,
        true
    );
    const context = useContext(ServerContext);
    const [fireEvents] = useAnalytics();
    let disclaimers: DisclaimerContent[] = [];

    const { queryModel, queryYear } = useDyfQueryParams();
    const vin = props.vin;
    const {
        data: dyfEligible,
        loading: dyfElgLoading,
        executeQuery,
    } = useDyfEligibleModel(null, true);

    useEffect(() => {
        executeQuery(
            vin && vin !== ''
                ? { vin }
                : { modelAlias: queryModel, year: queryYear }
        );
    }, [props, props.vin]);

    const isDyfEligible = useMemo(() => dyfEligible !== null, [
        dyfElgLoading,
        dyfEligible,
    ]);

    const handleTabEvent = (tabTitle: string) => {
        return async () =>
            fireEvents('vehicle-hub-tabs', undefined, {
                tabTitle,
            });
    };

    const renderListItems = (tile: QuickLinksContent): ReactNode => {
        if (tile.type === DYF_TILE_TYPE) {
            return <FMListItemDYF {...props} {...tile} />;
        } else {
            return (
                <FMListItem
                    key={tile.tileTitle}
                    title={tile.tileTitle}
                    description={tile.tileDescription || ''}
                    imgPath={''}
                    type={'no-icon'}
                    ctaPath={transformRelativeUrlValidateRoot(
                        ctaHref({ ...props, ...tile }),
                        context.root
                    )}
                    onClick={() =>
                        handleListItemEvent({ ...props, ...tile }, fireEvents)
                    }
                    showChevron={true}
                />
            );
        }
    };
    const handleClickEvent = async (
        ctaPath = '' as string,
        eventName: string,
        shortCodeProvider = {} as ShortcodeProviders
    ) => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (eventName) {
            fireEvents(eventName, undefined, shortCodeProvider);
        }
    };

    const hubContent = (title: string, tabIndex: number) => {
        const tab =
            fmHubContent?.[`tab${tabIndex.toString()}` as keyof FMHubFragment];
        if (
            typeof tab === 'object' &&
            tab !== null &&
            'fmListItems' in tab &&
            tab.title
        ) {
            let processedSkinny5050Banner: FMBannersProps | undefined =
                tab.skinny5050Banner;
            if (tab.skinny5050Banner) {
                const [
                    processedFMBanner,
                    processedDisclaimers,
                ] = DisclaimersService.processFMBanners(
                    tab.skinny5050Banner,
                    disclaimers
                );
                processedSkinny5050Banner = processedFMBanner;
                disclaimers = disclaimers.concat(processedDisclaimers);
            }

            const bannerName = tab.skinny5050Banner?.title
                ?.toString()
                ?.replace(/\[disclaimer\]/g, '')
                ?.trim();
            return {
                title,
                panel: (
                    <>
                        {processedSkinny5050Banner &&
                            !processedSkinny5050Banner.hide && (
                                <FMCardFiftyFifty
                                    {...processedSkinny5050Banner}
                                    {...{
                                        title: '',
                                        headline:
                                            processedSkinny5050Banner.title,
                                        description:
                                            processedSkinny5050Banner.description,
                                        media: {
                                            src:
                                                process.env
                                                    .REACT_APP_AEM_BASE_URL +
                                                processedSkinny5050Banner.imagePath,
                                            alt:
                                                processedSkinny5050Banner.imageAltText,
                                        },
                                        button: {
                                            type: 'primary',
                                            label:
                                                processedSkinny5050Banner.cta1Label,
                                            disabled: false,
                                            ariaLabel:
                                                processedSkinny5050Banner.cta1AriaLabel,
                                            href:
                                                processedSkinny5050Banner.cta1Path,
                                            target: processedSkinny5050Banner.openCTA1InNewTab
                                                ? '_blank'
                                                : '_self',
                                            onClick: () => {
                                                handleClickEvent(
                                                    processedSkinny5050Banner?.cta1Path,
                                                    'fm-hub-fifty-fifty-card',
                                                    {
                                                        bannerName,
                                                        ctaType:
                                                            processedSkinny5050Banner?.cta1Label,
                                                    }
                                                );
                                            },
                                        },
                                        secondaryButton: {
                                            type: 'secondary',
                                            label:
                                                processedSkinny5050Banner.cta2Label,
                                            disabled: false,
                                            ariaLabel:
                                                processedSkinny5050Banner.cta2AriaLabel,
                                            href:
                                                processedSkinny5050Banner.cta2Path,
                                            target: processedSkinny5050Banner.openCTA2InNewTab
                                                ? '_blank'
                                                : '_self',
                                            onClick: () => {
                                                handleClickEvent(
                                                    processedSkinny5050Banner?.cta2Path,
                                                    'fm-hub-fifty-fifty-card',
                                                    {
                                                        bannerName,
                                                        ctaType:
                                                            processedSkinny5050Banner?.cta2Label,
                                                    }
                                                );
                                            },
                                        },
                                        mediaSide: 'left',
                                    }}
                                />
                            )}
                        {tab.fmListItems
                            .filter(tile => {
                                return (
                                    tile.type !== DYF_TILE_TYPE || isDyfEligible
                                );
                            })
                            .map(tile => renderListItems(tile))}
                    </>
                ),
                handleClick: handleTabEvent(title),
            };
        }
        return { title, panel: null };
    };

    const fmHubTabs = () => {
        if (!fmHubContent?.hide) {
            return (
                fmHubContent && (
                    <FMTabsWithAccordion
                        panels={[
                            hubContent(fmHubContent?.tab1?.title, 1),
                            hubContent(fmHubContent?.tab2?.title, 2),
                            hubContent(fmHubContent?.tab3?.title, 3),
                            hubContent(fmHubContent?.tab4?.title, 4),
                            hubContent(fmHubContent?.tab5?.title, 5),
                            hubContent(fmHubContent?.tab6?.title, 6),
                            hubContent(fmHubContent?.tab7?.title, 7),
                        ].filter(panel => !!panel.title)}
                    />
                )
            );
        }
    };
    return {
        fmHubsTabs: fmHubTabs(),
        fmHubsDisclaimers: disclaimers,
    };
}
