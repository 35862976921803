import { AxiosResponse } from 'axios';
import HttpService from '../http-service/http-service';
import { ContentResponse } from './content-service.interface';
import { HttpsProxyAgent } from 'https-proxy-agent';

export class ContentService {
    public getContent(
        category: string,
        name: string,
        brand: string,
        region: string,
        language: string,
        ymmServlet?: boolean
    ): Promise<ContentResponse> {
        let url;
        let urlNames;
        const multipleResponse: any = [];
        const contentFragmentBaseUrl = `${process.env.REACT_APP_AEM_BASE_URL}/content/dam/global-owner/${brand}`;
        if (ymmServlet) {
            url = `${contentFragmentBaseUrl}/${region}/${language}/content-fragments/vehicles.${name}.json`;
        } else {
            if (name.indexOf('|') > -1) {
                urlNames = name.split('|');
                urlNames.pop();
            }
            url = `${contentFragmentBaseUrl}/${region}/${language}/content-fragments/${category}/${name}.global-owner-model.json`;
        }
        if (urlNames) {
            url = `${contentFragmentBaseUrl}/${region}/${language}/content-fragments/${category}/warranty-quick-guides/powertrain-warranty.global-owner-model.json`;
            urlNames.forEach(urlName => {
                url = `${contentFragmentBaseUrl}/${region}/${language}/content-fragments/${category}/warranty-quick-guides/${urlName}.global-owner-model.json`;
                this.get(url).then(response => {
                    multipleResponse.push(response.data);
                });
            });
            return this.get(url).then(response => {
                response.data.elements = [];
                multipleResponse.map((pos: any, index: number) => {
                    response.data.elements[index] = multipleResponse[index];
                });
                return response.data;
            });
        } else {
            return this.get(url).then(response => response.data);
        }
    }

    public getExperience<T>(
        category: string,
        name: string,
        componentName: string,
        brand: string,
        region: string,
        language: string,
        ymmServlet?: boolean
    ): Promise<T> {
        let url;
        const experienceFragmentBaseUrl = `${process.env.REACT_APP_AEM_BASE_URL}/content/experience-fragments/global-owner/${brand}`;
        if (ymmServlet) {
            url = `${experienceFragmentBaseUrl}/${region}/${language}/vehicles.${name}.json`;
        } else if (
            componentName === 'dynamicPhoneNumberDropdown' ||
            componentName === 'euhelprouter'
        ) {
            url = `${experienceFragmentBaseUrl}/${category}/${name}/master.model.json`;
        } else {
            url = `${experienceFragmentBaseUrl}/${region}/${language}/${category}/${name}/master.model.json`;
        }

        if (
            url.includes('category') ||
            (url.includes('learning-hub') &&
                !(
                    url.includes('yearsList') ||
                    url.includes('random-cards') ||
                    url.includes('Img-CX-Articles-Search')
                )) ||
            url.includes('recalls-landing')
        ) {
            return this.get(url).then(response => {
                return {
                    ...response?.data[':items'],
                    ':type': response?.data[':type'],
                };
            });
        }
        return this.get(url).then(response => {
            return response?.data[':items']?.root[':items'][componentName];
        });
    }

    private get(url: string): Promise<AxiosResponse<any>> {
        if (url.includes('globalowneraem')) {
            return HttpService.get(url, true, {
                httpsAgent: new HttpsProxyAgent(
                    process.env.https_proxy || 'http://internet.ford.com:83'
                ),
            });
        } else return HttpService.get(url, true);
    }
}

const contentService = new ContentService();
export default contentService;
