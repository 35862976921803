import {
    SyncMapsCard,
    SyncMapsConsolidatedResponse,
    SyncMapStatus,
} from '../use-syncs-maps-status-updates';
export interface GetSyncACcordionProps {
    syncOption: string;
    syncMapsUpdates: SyncMapsConsolidatedResponse | undefined;
    syncGeneration: string;
    syncCards: SyncMapsCard[] | undefined;
    syncMapContent: SyncMapStatus | null;
    dealerOnlyCardExists: boolean | undefined;
    hasTicket: boolean;
    isSyncOta: boolean;
}
export const getSyncAccordionContent = (props: GetSyncACcordionProps) => {
    const {
        syncOption,
        syncMapsUpdates,
        syncGeneration,
        syncCards,
        syncMapContent,
        dealerOnlyCardExists,
        hasTicket,
        isSyncOta,
    } = props;
    return {
        selectedOption: syncOption,
        hasSync:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.hasSync,
        hasUpdate:
            isSyncOta ||
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.hasFirmwareUpdate,
        syncGeneration,
        cards: syncCards || [],
        heading: syncMapContent?.syncHeading,
        title: isSyncOta
            ? syncMapContent?.syncAutomaticUpdates
            : syncMapContent?.syncUpdateAvailable,
        subtitle: syncMapContent?.syncUpdateAvailableDescription,
        uploadPending: syncMapContent?.uploadPendingMessage,
        radioButtons: syncGeneration !== '4' && syncGeneration !== '5',
        upToDate: syncMapContent?.syncIsUpToDate,
        syncMapContent: syncMapContent,
        downloadUrl:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                .downloadUrl,
        downloadUrlError:
            (syncGeneration === '1' ||
                syncGeneration === '2' ||
                syncGeneration === '3') &&
            !syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                .downloadUrl,
        notAvailable: syncMapContent?.notAvailableLabel,
        accordionType: 'sync',
        eulaURL:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.eulaURL,
        error:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.syncError === 'Vehicle identified as SYNC GEN4' ||
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                ?.syncError === 'Vehicle identified as SYNC GEN5'
                ? ''
                : syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse
                      ?.syncError ||
                  syncMapsUpdates?.syncMapUpdateResponse?.error,
        dealerOnlyCardExists: dealerOnlyCardExists,
        hasTicket: hasTicket,
    };
};
