import { useEffect, useState } from 'react';

type ToggleTypes =
    | 'enableUnauthFeatureCategory'
    | 'enableVehicleSelector'
    | 'enableAemImageRenditions'
    | 'disableUpcomingLivestreams';

export const useOnboardingFeatureToggles = (): Record<ToggleTypes, boolean> => {
    const [state, setState] = useState<Record<ToggleTypes, boolean>>({
        enableUnauthFeatureCategory: false,
        enableVehicleSelector: false,
        enableAemImageRenditions: false,
        disableUpcomingLivestreams: true,
    });

    useEffect(() => {
        if (
            ['local', 'test', 'dev', 'qa'].includes(
                process.env.REACT_APP_ACTIVE_PROFILE?.toLowerCase() ?? ''
            )
        ) {
            return setState(prev => ({
                ...prev,
                enableUnauthFeatureCategory: true,
                enableAemImageRenditions: true,
            }));
        }
        if (process.env.REACT_APP_ACTIVE_PROFILE?.toLowerCase() === 'prod') {
            return setState(prev => ({
                ...prev,
                enableAemImageRenditions: true,
            }));
        }
    }, []);

    return state;
};
