import React from 'react';
import { BreadcrumbsReimagine } from '../../components/sections';
import { useSearchContent } from '../../components/sections/search-bar/hooks/use-search-content';
import { useHelpCardsContent } from '../page-not-found-view/hooks/use-help-cards-content';
import { CategoryViewMasthead } from '../../components/sections/category/categories/category-view-masthead/category-view-masthead';
import { FaqsAccordion } from '../../components/sections/category/categories/faqs-accordion/faqs-accordion';
import { CategoryQuietCards } from '../../components/sections/category/categories/quiet-cards/category-quiet-cards';
import { CategoryPopularArticlesTrio } from '../../components/sections/category/categories/category-popular-articles-trio/category-popular-articles-trio';
import { CategoryVideoQuietCard } from '../../components/sections/category/categories/category-video-quiet-card/category-video-quiet-card';
import { useAnalytics } from '../../hooks/use-analytics';
import { DisclaimerList } from '../../components/common/disclaimer-list/disclaimer-list';
import '../category-view/category-view.scss';
import DisclosuresLinkSyndicationAware from '../../components/sections/disclosures-link/disclosures-link-syndication-aware';
import { CategoryOnClickCtaInfo } from '../../services/shortcode-service/shortcode-service';
import './sam-reimagine-category-view.scss';
import {
    SamReImagineCategoryViewContentReturn,
    SamReImagineCategoryViewFragment,
} from '../../models/experiencefragments/sam-reimagine-category-view';
import { FMCard, FMCarousel } from '@own/fds-react';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import { PreImagineEscapeHatch } from '../../components/sections/pre-imagine-components/pre-imagine-escape-hatch/pre-imagine-escape-hatch';
import { ComponentBox5050 } from '../../components/sections/category/categories/component-box-5050/component-box-5050';

interface Props {
    path: string;
    categoryId: string;
    categoryViewFragment: SamReImagineCategoryViewFragment;
    breadcrumbs: any;
    useCategoryViewContent: (
        categoryViewFragment: SamReImagineCategoryViewFragment
    ) => SamReImagineCategoryViewContentReturn | undefined;
    pageName: string;
    isShowMoreInfo?: boolean;
}

export const SamReImagineCategoryView = (props: Props) => {
    const { path, categoryViewFragment, pageName } = props;

    useAnalytics('owner-category', undefined, {
        categoryId: path,
    });

    const [fireEvents] = useAnalytics();
    const categoryFaqPanelClick = async (panelTitle: string) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            categoryPath: props.path,
            panelTitle,
        };
        fireEvents('faq-onclick-event', undefined, {
            categoryOnClickCtaInfo,
        });
    };

    const { disclaimers, content } =
        props.useCategoryViewContent(categoryViewFragment) || {};
    const searchContent = useSearchContent();
    const helpCardsContent = useHelpCardsContent();

    return (
        <>
            {content && (
                <div className="sam-reimagine-category-view">
                    {!props.categoryViewFragment.breadcrumbs
                        ?.hideBreadcrumbs && (
                        <BreadcrumbsReimagine
                            currentPage={props.categoryId}
                            type="common"
                            isShowMoreInfo={props.isShowMoreInfo}
                        >
                            <props.breadcrumbs />
                        </BreadcrumbsReimagine>
                    )}
                    {content.masthead && (
                        <CategoryViewMasthead
                            {...content.masthead}
                            eventName={'category-onclick-event'}
                            categoryPath={path}
                            class={props.pageName}
                        />
                    )}
                    {content.infoQuietCards?.articles && (
                        <div className={`info-quiet-cards-${props.pageName}`}>
                            <CategoryQuietCards
                                articles={content.infoQuietCards.articles}
                                categoryType={'category'}
                                categoryPath={path}
                                className={pageName}
                            />
                        </div>
                    )}
                    {content.quietCardsOne && (
                        <div className={`quiet-cards-one-${props.pageName}`}>
                            <CategoryQuietCards
                                articles={content.quietCardsOne.articles}
                                categoryType={'category'}
                                categoryPath={path}
                                buttonColor={'dark'}
                                buttonFill={'fill'}
                                firstCardImageContent={false}
                            />
                        </div>
                    )}
                    {content.videoQuietCard && (
                        <CategoryVideoQuietCard
                            {...content.videoQuietCard}
                            class={
                                content.videoQuietCard.videoQuietCardDetails
                                    .classname
                            }
                            showDisclaimer={true}
                        />
                    )}
                    {content.articleCarousel && (
                        <div className="ca-article-carousel">
                            {content.articleCarousel.title && (
                                <h2 className="ca-article-carousel__title">
                                    {content.articleCarousel.title}
                                </h2>
                            )}
                            {content.articleCarousel.subtitle && (
                                <div className="ca-article-carousel__subtitle">
                                    {content.articleCarousel.subtitle}
                                </div>
                            )}
                            <FMCarousel
                                items={content.articleCarousel.cards}
                                render={(item: FMCardProps) => (
                                    <FMCard
                                        {...item}
                                        imageSrc={`${process.env.REACT_APP_AEM_BASE_URL}${item.imageSrc}`}
                                        ctaType={'secondary'}
                                        ctaTheme={'light'}
                                    >
                                        {item?.children}
                                    </FMCard>
                                )}
                            />
                        </div>
                    )}
                    {content.quietCards && (
                        <div className={`quiet-cards-${props.pageName}`}>
                            <CategoryQuietCards
                                articles={content.quietCards.articles}
                                categoryType={'category'}
                                categoryPath={path}
                                buttonColor={'dark'}
                                buttonFill={'outline'}
                                firstCardImageContent={false}
                            />
                        </div>
                    )}
                    {content.componentBox5050 && (
                        <div className={`box-50-50-${props.pageName}`}>
                            <ComponentBox5050
                                content={content.componentBox5050}
                                {...content.componentBox5050}
                            />
                        </div>
                    )}
                    {content.articlesTrio && (
                        <CategoryPopularArticlesTrio
                            {...content.articlesTrio}
                            categoryPath={path}
                            categoryType={'category'}
                        />
                    )}
                    {content.faqs && (
                        <FaqsAccordion
                            {...content.faqs}
                            onPanelClickEvent={categoryFaqPanelClick}
                            pageType="Category"
                        />
                    )}
                    {searchContent && helpCardsContent && (
                        <PreImagineEscapeHatch
                            searchContent={searchContent}
                            helpCardsContent={helpCardsContent}
                        />
                    )}
                    {disclaimers && (
                        <DisclaimerList
                            disclaimers={disclaimers}
                        ></DisclaimerList>
                    )}
                    <DisclosuresLinkSyndicationAware />
                </div>
            )}
        </>
    );
};
