import React, { useRef, useState, ReactNode, useEffect } from 'react';
import { useTooltipContent } from './hook/use-tooltip';
import { KEYBOARD_KEYS } from '../../../constants';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { FloatingPanel } from '../floating-panel/floating-panel';
import './tooltip.scss';
import { useAnalytics } from '../../../hooks/use-analytics';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import { TooltipPanel } from './tooltip-panel';

interface Props {
    className?: string;
    tooltipSymbol: ReactNode;
    tooltipHeader?: string;
    tooltipContent: string;
    osbServiceTooltip?: boolean;
    customShowTooltipAriaLabel?: string;
    customDismissTooltipAriaLabel?: string;
    uniqueId?: number;
    eventName?: string;
    onClick?: () => void; // define a prop for click event
    dataTestId?: string;
}

export const ToolTip = (props: Props) => {
    const { brand } = new AppConfigurationService();
    const isOsbServiceTooltip = !!props.osbServiceTooltip;
    const tooltipOsb = isOsbServiceTooltip
        ? {
              showTooltipAriaLabel: 'osbShowTooltip',
              dismissTooltipAriaLabel: 'osbDismissTooltip',
          }
        : null;
    const toolTipHook = useTooltipContent();
    const tooltipContent = isOsbServiceTooltip ? tooltipOsb : toolTipHook;

    const [hidden, setHidden] = useState<boolean>(true);
    const toolTip = useRef<HTMLDivElement>(null);
    const floatingPanelRef = React.createRef<HTMLDivElement>();
    const iconToolTipButton = useRef<HTMLButtonElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const [fireEvents] = useAnalytics();
    const isMobile = ServerSideService.isClientSide()
        ? window.innerWidth <= 780
        : false;
    const closeToolTipHandler = () => {
        setHidden(true);
        setTimeout(() => {
            if (iconToolTipButton && iconToolTipButton.current) {
                if (isMobile) {
                    iconToolTipButton.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
                iconToolTipButton.current.focus();
            }
        }, 0);
    };

    const openToolTipHandler = () => {
        setTimeout(() => {
            if (closeButtonRef && closeButtonRef.current) {
                if (isMobile) {
                    window.scrollTo(0, 0);
                }
                closeButtonRef.current.focus();
            }
        }, 0);
        if (props.eventName) {
            fireEvents(props.eventName, undefined, undefined, false);
        }
        setHidden(false);
    };

    const nextFocus = (nextFocusClass: any) => {
        const nextFocusElement = document.querySelector(
            nextFocusClass
        ) as HTMLElement;
        nextFocusElement?.focus();
    };

    useEffect(() => {
        const onClick = (event: MouseEvent) => {
            if (props.onClick) {
                props.onClick();
            }
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            hidden ? openToolTipHandler() : closeToolTipHandler();
        };
        if (iconToolTipButton.current) {
            iconToolTipButton.current.addEventListener('click', onClick);
        }
        return () => {
            if (iconToolTipButton.current) {
                iconToolTipButton.current.removeEventListener('click', onClick);
            }
        };
    });

    const userClicksOutside = (event: MouseEvent) => {
        return (
            iconToolTipButton.current &&
            !iconToolTipButton.current.contains(event.target as Node)
        );
    };

    const userClicksOutsideFloatingPanel = (event: MouseEvent) => {
        return (
            floatingPanelRef.current &&
            !floatingPanelRef.current.contains(event.target as Node)
        );
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (userClicksOutside(event) && userClicksOutsideFloatingPanel(event)) {
            closeToolTipHandler();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, true);
        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    }, [hidden]);

    return (
        <>
            {tooltipContent && props.tooltipContent && (
                <span
                    className={['tooltip', props.className].join(' ')}
                    ref={toolTip}
                >
                    <button
                        data-testid={props.dataTestId}
                        className="tooltip__button"
                        aria-label={
                            props?.customShowTooltipAriaLabel ||
                            tooltipContent?.showTooltipAriaLabel
                        }
                        ref={iconToolTipButton}
                        onKeyDown={event => {
                            if (event.key === KEYBOARD_KEYS.ENTER) {
                                event.preventDefault();
                                hidden
                                    ? openToolTipHandler()
                                    : closeToolTipHandler();
                            }
                            nextFocus(`.tooltip__inner-content`);
                        }}
                    >
                        {props.tooltipSymbol}
                    </button>
                    {iconToolTipButton.current && (
                        <FloatingPanel
                            parent={iconToolTipButton.current}
                            hidden={hidden}
                            ref={floatingPanelRef}
                        >
                            <TooltipPanel
                                tooltipContent={props.tooltipContent}
                                closeButtonRef={closeButtonRef}
                                closeToolTipHandler={closeToolTipHandler}
                                nextFocus={nextFocus}
                                brand={brand}
                                customDismissTooltipAriaLabel={
                                    props.customDismissTooltipAriaLabel
                                }
                                tooltipHeader={props.tooltipHeader}
                                tooltipOsb={tooltipContent}
                            />
                        </FloatingPanel>
                    )}
                </span>
            )}
        </>
    );
};
