import React, { useContext, useEffect, useState } from 'react';
import { QuickLinksContent } from '../../../../models/experiencefragments/quick-links';
import { useHistory } from 'react-router-dom';
import ServerContext from '../../../../contexts/serverContext';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { findByAlias } from '../../../../routes';
import { FMListItem } from '@own/fds-react';
import { handleListItemEvent } from '../../../quicklinks/quick-links-util';
import { useAnalytics } from '../../../../hooks/use-analytics';

const FMListItemDYF = (props: VehicleAttributes & QuickLinksContent) => {
    const context = useContext(ServerContext);
    const [ctaPath, setCtaPath] = useState('');
    const history = useHistory();
    const [fireEvents] = useAnalytics();
    const showTrimPage =
        ['us', 'ca'].includes(context.currentRegionCode) && !props.vin;

    useEffect(() => {
        const dyfLandingViewRoutes: any[] = findByAlias('DyfLandingView');
        if (
            showTrimPage &&
            dyfLandingViewRoutes !== undefined &&
            dyfLandingViewRoutes?.length !== 0
        ) {
            setCtaPath(
                dyfLandingViewRoutes[1]
                    .replace(':year', props.year.toString())
                    .replace(':model', props.seoKey)
            );
        } else if (
            props.vin &&
            dyfLandingViewRoutes !== undefined &&
            dyfLandingViewRoutes?.length !== 0
        ) {
            setCtaPath(dyfLandingViewRoutes[0].replace(':vin', props.vin));
        } else setCtaPath(props.staticCTAPath);
    }, [props.vin, props.year, props.seoKey]);

    return (
        <FMListItem
            key={props.tileTitle}
            title={props.tileTitle}
            description={props.tileDescription || ''}
            imgPath={''}
            type={'no-icon'}
            ctaPath={showTrimPage ? '' : ctaPath}
            onClick={(e: React.MouseEvent) => {
                handleListItemEvent(props, fireEvents);
                if (ctaPath && (showTrimPage || props.vin)) {
                    e.preventDefault();
                    history.push(ctaPath);
                }
            }}
            showChevron={true}
        />
    );
};

export default FMListItemDYF;
