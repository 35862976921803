import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    getModelOptionsForYear,
    getYearOptions,
    VehicleYmm,
    VehicleYmmGroup,
} from '../../../../models/vehicle-ymm';
import { Dropdown, PrimaryButton } from '../../../common';
import { buildPath, findByAlias } from '../../../../routes';
import { useHomeContent } from '../../../../views/home-page/hooks/use-home-content';
import { RedirectData } from '../../../../support/components/FMALogin/FMALogin';

import './ymm-selector.scss';
import ServerContext from '../../../../contexts/serverContext';
import siteMapService from '../../../../support/services/site-map-service/site-map-service';
import { VehicleListServiceAem } from '../../../../services/vehicle-list-service-aem/vehicle-list-service-aem';
import { getAemVehicleModelData } from '../../../../hooks/use-aem-vehicle-model-data';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { Heading } from '../../../common/heading/heading';
import { pageTypesForH3 } from '../../../../support/support-constants';
import localStorageWrapper from '../../../utils/local-storage-wrapper/localStorageWrapper';
import { scrollToTop } from '../../vehicle-health/vha-utils';
import serverSideService from '../../../../services/server-side-service/server-side-service';

const DEFAULT_VEHICLE_GROUP: VehicleYmmGroup[] = [];
const DEFAULT_YEAR = 0;
const DEFAULT_MODEL = '';

interface Props {
    ymmHeaderLabel?: string;
    yearDropdownLabel: string;
    modelDropdownLabel: string;
    submitLabel: string;
    vehicleDropdownData?: VehicleYmmGroup[];
    redirectData?: RedirectData;
    eventName?: string;
    handleDropDownChange: ((year: number, model: string) => void) | undefined;
    handleYearModelSubmit?:
        | ((
              model: string,
              selectedYear: number,
              vehicleYmm?: VehicleYmm
          ) => void)
        | undefined;
    isTabs?: boolean;
    ymmSubmitEvent?: () => void;
}

const YMMSelector = (props: Props) => {
    const {
        root,
        currentLanguageRegionCode,
        currentRegionCode,
        brand,
    } = useContext(ServerContext);
    const currentRoot = root ? root.substring(0, root.length - 1) : '';
    const { handleDropDownChange, handleYearModelSubmit } = props;
    const [modelsByYear, setModelsByYear] = useState<VehicleYmmGroup[]>(
        props.vehicleDropdownData || DEFAULT_VEHICLE_GROUP
    );
    const [selectedYear, setSelectedYear] = useState<number>(DEFAULT_YEAR);
    const [selectedModel, setSelectedModel] = useState<string>(DEFAULT_MODEL);
    const [yearErrorMessage, setYearErrorMessage] = useState<
        string | undefined
    >('');
    const [modelErrorMessage, setModelErrorMessage] = useState<
        string | undefined
    >('');

    const yearOptions: string[] = getYearOptions(modelsByYear);
    const modelOptions = getModelOptionsForYear(modelsByYear, selectedYear);

    const history = useHistory();
    const homeContent = useHomeContent();

    const [fireEvents] = useAnalytics();

    const [yearErrorMessageText, modelErrorMessageText] = [
        homeContent?.vehicleSelectorYearErrorMsg,
        homeContent?.vehicleSelectorModelErrorMsg,
    ];

    const selectVehicle = async (model: string) => {
        const vehicleYmm = await getAemVehicleModelData(
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            selectedYear,
            selectedModel,
            'DISPLAY_MODEL_NAME'
        );

        if (model && selectedYear && vehicleYmm?.seoKey) {
            // else if we need to show results in a new page with a redirect...
            switch (props.redirectData?.id) {
                case 'warranty-information':
                    history.push({
                        pathname: findByAlias('OwnerManualDetailsPage')
                            .replace(':model+', vehicleYmm.seoKey)
                            .replace(':year', selectedYear.toString()),
                        state: {
                            scrollTop: true,
                        },
                    });
                    break;
                case 'owner-manual':
                    window.location.href = `${currentRoot}${siteMapService
                        .getPathFromRouterConfigs(
                            currentLanguageRegionCode,
                            brand,
                            'OwnerManualDetailsPage',
                            [vehicleYmm.seoKey, selectedYear.toString()]
                        )
                        ?.replace('+', '')}`;
                    break;
                case 'owner-manual-details':
                    if (serverSideService.isClientSide()) {
                        localStorageWrapper.removeItem('USER_VIN');
                        sessionStorage.removeItem('QUERY_VIN');
                    }

                    props.ymmSubmitEvent && props.ymmSubmitEvent;

                    history.push(
                        `${buildPath('OwnerManualDetailsPage')
                            .replace(':model+', vehicleYmm.seoKey)
                            .replace(':year', selectedYear)}`
                    );
                    scrollToTop();

                    break;
                case 'recalls':
                case 'recalls-details':
                    localStorageWrapper.setItem('USER_VIN', '');
                    history.push(
                        `${buildPath('RecallsDetailsPage')
                            .replace(':vinOrModel?', vehicleYmm.seoKey)
                            .replace(':year?', selectedYear)}`
                    );
                    props.ymmSubmitEvent && props.ymmSubmitEvent;

                    break;
                default:
                    history.push(
                        buildPath(
                            'VehicleYmmView',
                            vehicleYmm.seoKey,
                            String(selectedYear)
                        )
                    );
                    break;
            }
        }
    };

    const onYearDropdownChange = (value: string) => {
        setYearErrorMessage('');
        setSelectedYear(parseInt(value, 10));
    };

    const onModelDropdownChange = (value: string) => {
        setModelErrorMessage('');
        setSelectedModel(value);
        if (handleDropDownChange) {
            handleDropDownChange(selectedYear, value);
        }
    };

    const onModelDropdownClick = () => {
        if (!selectedYear) {
            setYearErrorMessage(yearErrorMessageText);
        }
    };

    const onYMMSubmit = async () => {
        setYearErrorMessage('');

        if (!selectedYear) {
            setYearErrorMessage(yearErrorMessageText);
        } else if (
            selectedYear &&
            (!selectedModel || selectedModel === props.modelDropdownLabel)
        ) {
            setModelErrorMessage(modelErrorMessageText);
        } else if (handleYearModelSubmit) {
            // We need to recover results from the API and update the current page (service-information)
            const vehicleYmm = await getAemVehicleModelData(
                brand,
                currentRegionCode,
                currentLanguageRegionCode,
                selectedYear,
                selectedModel,
                'DISPLAY_MODEL_NAME'
            );
            handleYearModelSubmit(selectedModel, selectedYear, vehicleYmm);
        } else {
            selectVehicle(selectedModel);
        }
    };

    useEffect(() => {
        if (!props.vehicleDropdownData) {
            new VehicleListServiceAem()
                .getVehicles(
                    brand,
                    currentRegionCode,
                    currentLanguageRegionCode
                )
                .then(setModelsByYear)
                .catch(console.error);

            return function cleanup() {
                setModelsByYear(DEFAULT_VEHICLE_GROUP);
            };
        }
    }, []);
    const type =
        props.redirectData?.id && pageTypesForH3.includes(props.redirectData.id)
            ? 'h3'
            : 'h2';
    const dropDownsEmpty =
        !(selectedYear > 0 && selectedModel) && props?.isTabs;
    return (
        <div className="ymm-vin-container">
            {props.ymmHeaderLabel && (
                <div className="ymm-vin-header">
                    <Heading type={type} className="selector-label">
                        {props.ymmHeaderLabel}
                    </Heading>
                </div>
            )}
            <div className="ymm-vin-body">
                <div className="ymm-container-dropdown">
                    <Dropdown
                        label={props.yearDropdownLabel}
                        noLabel={true}
                        className="year-dropdown"
                        options={yearOptions}
                        value={selectedYear}
                        errorMessage={yearErrorMessage}
                        onChange={onYearDropdownChange}
                        id="year-dropdown"
                        dataTestId="year-dropdown"
                    />
                    <Dropdown
                        label={props.modelDropdownLabel}
                        noLabel={true}
                        disabled={!selectedYear}
                        options={modelOptions}
                        className="model-dropdown"
                        value={selectedModel}
                        errorMessage={modelErrorMessage}
                        onChange={onModelDropdownChange}
                        onClick={onModelDropdownClick}
                        id="model-dropdown"
                        dataTestId="model-dropdown"
                    />
                </div>
                <PrimaryButton
                    className="vehicle-selector-button"
                    color="dark"
                    fill="fill"
                    chevron={true}
                    ariaLabel={props.submitLabel}
                    onClick={() => {
                        if (props.eventName) {
                            fireEvents(
                                props.eventName,
                                undefined,
                                {
                                    ctaType: 'model',
                                },
                                false
                            );
                        }
                        onYMMSubmit();
                    }}
                    dataTestId="ymm-vehicle-selector-submit-button"
                    disabled={dropDownsEmpty}
                >
                    {props.submitLabel}
                </PrimaryButton>
            </div>
        </div>
    );
};

export default YMMSelector;
