import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { SupportCommonProps } from '../../../../support/components/astute-topic/hook/use-support-common';
import { SyncCitivanCheckVersionProps } from '../use-sync-citivan-check-a-version';
import {
    SyncMapsCard,
    SyncMapsConsolidatedResponse,
    SyncMapStatus,
} from '../use-syncs-maps-status-updates';
interface Props {
    syncOption: string;
    vehicleInfo: VehicleAttributes | undefined;
    experienceContent: SyncMapStatus | null;
    syncGeneration: string;
    vwCards: SyncMapsCard[] | undefined;
    syncMapContent: SyncMapStatus | null;
    syncMapsUpdates: SyncMapsConsolidatedResponse | undefined;
    supportCommonContent: SupportCommonProps | null;
    releaseDate: string;
    vwContent: SyncCitivanCheckVersionProps | null;
}
export const getVwAccordionContent = (props: Props) => {
    const {
        syncOption,
        vehicleInfo,
        experienceContent,
        syncGeneration,
        vwCards,
        syncMapContent,
        syncMapsUpdates,
        supportCommonContent,
        releaseDate,
        vwContent,
    } = props;
    const vwVersionDescription = vwContent?.vwVersionLabel
        ? vwContent?.vwVersionLabel
        : supportCommonContent?.releaseDateLabel;
    return {
        selectedOption: syncOption,
        hasVw:
            vehicleInfo?.citivanVin &&
            !experienceContent?.vwHide &&
            experienceContent?.vwHeading !== undefined,
        hasUpdate: true,
        syncGeneration,
        cards: vwCards || [],
        heading: syncMapContent?.vwHeading,
        title: syncMapContent?.vwMapsUpdateAvailable,
        subtitle:
            syncMapContent?.vwUpdateAvailableDescription +
            ' ' +
            vwVersionDescription +
            ' ' +
            syncMapsUpdates?.vwMapsUpdateResponse?.version,
        radioButtons: false,
        upToDate: syncMapContent?.vwMapsUpToDate,
        syncMapContent: syncMapContent,
        downloadUrl: syncMapsUpdates?.vwMapsUpdateResponse?.downloadUrl,
        downloadUrlError: syncMapsUpdates?.vwMapsUpdateResponse?.downloadUrl
            ? false
            : true,
        notAvailable: syncMapContent?.vwMapsNotAvailable,
        accordionType: 'vw',
        releaseDate: releaseDate,
        eulaURL:
            syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.eulaURL,
        error: '',
        vwVersion: syncMapsUpdates?.vwMapsUpdateResponse?.version,
    };
};
