import React, { useContext, useEffect } from 'react';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import { VideoTile } from './video-tile/video-tile';
import './video-tiles.scss';
import { useServerData } from '../../../../hooks/use-server-data';
import PopularVideoService from '../../../../services/onboarding-brightcove-video-service/popular-video-service';
import ServerContext from '../../../../contexts/serverContext';
import { Video } from '../../../../services/onboarding-brightcove-video-service/popular-video-service.types';
import { usePopularVideosContent } from '../../live-stream/components/hooks/use-popular-videos';
import { FMButton } from '@own/fds-react';

interface Props {
    vehicleAttributes: VehicleAttributes;
    origin: string;
    seoKey?: string;
}

export const VideoTiles = (props: Props) => {
    const popularVideoContent = usePopularVideosContent();
    const serverContext = useContext(ServerContext);
    const currentLanguageRegionCode = serverContext.currentLanguageRegionCode;
    const baseRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const popularVideos = useServerData(
        'popularVideosByViewCount',
        async () => {
            const popularVideoService = new PopularVideoService();
            return popularVideoService.getPopularVideosByViewCount(
                props.vehicleAttributes.make,
                currentLanguageRegionCode,
                props.vehicleAttributes.model,
                props.vehicleAttributes.year
            );
        },
        []
    )[0]?.data;

    const replaceYMM = (data: string) => {
        return data
            ?.replace('[year]', props.vehicleAttributes.year.toString())
            ?.replace('[brand]', props.vehicleAttributes.make)
            ?.replace(
                '[model]',
                props.vehicleAttributes.seoKey?.toLowerCase() || ''
            );
    };

    useEffect(() => {
        const script = document.createElement('script');

        script.src = `https://players.brightcove.net/4095259350001/SyeO7cSQJf_default/index.min.js`;
        script.defer = true;

        document.body.appendChild(script);
    }, []);
    const popularVideoList = (videos: Video[]) => (
        <div className="onboarding-tile-grid">
            {videos.map(item => (
                <div key={item.id} id={item.id}>
                    <VideoTile
                        accountId={item.account_id}
                        videoId={item.id}
                        categoryName={item.custom_fields.category1}
                        make={item.custom_fields.make}
                        year={PopularVideoService.yearTrim(
                            item.custom_fields.year || '',
                            props.vehicleAttributes.year.toString()
                        )}
                        model={item.custom_fields.model}
                        name={item.name}
                        eventName={
                            props.origin === 'livestream'
                                ? 'dyf-livestream-popular-videos-onclick-event'
                                : 'dyf-interstitial-popular-videos-onclick-event'
                        }
                    />
                </div>
            ))}
        </div>
    );

    const pageHeader = () => {
        return (
            popularVideoContent && (
                <div className="popular-how-to-videos">
                    <h2 className="popular-how-to-videos-title">
                        {popularVideoContent.popularVideosTitle}
                    </h2>
                    <div>
                        <FMButton
                            chevron="right"
                            href={
                                baseRoot +
                                `${replaceYMM(
                                    popularVideoContent.popularVideosCta
                                )}`
                            }
                            label={popularVideoContent.popularVideosCtaTitle}
                            theme="light"
                            type="tertiary"
                            aria-label={replaceYMM(
                                popularVideoContent.popularVideosCtaAriaLabel
                            )}
                        />
                    </div>
                </div>
            )
        );
    };

    return (
        <>
            {popularVideos && popularVideos.length > 0 && (
                <div className="onboarding-popular-video-tiles-container">
                    {pageHeader()}
                    {popularVideoList(popularVideos.slice(0, 3))}
                </div>
            )}
        </>
    );
};
