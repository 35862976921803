import React, { useState } from 'react';
import ServiceHandler from '../../../services/service-handler';
import { Link } from '../../common';
import {
    SupportedLanguage,
    SupportedLanguageOverride,
} from '../../../services/app-configuration-service/app-configuration-service';

import './language-toggle.scss';
import { sanitizeUrl } from '../../utils/dom-util';
import DOMPurify from 'dompurify';

const languageGlobe = './icons/language-globe.svg';

interface Props {
    className?: string;
    tabIndex: number;
}

export const LanguageToggle = (props: Props) => {
    const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);

    const appConfigurationService = ServiceHandler.AppConfigurationService;
    const {
        currentLanguage,
        currentDomain,
        currentRoot,
    } = appConfigurationService;

    const [languages] = useState<SupportedLanguage[]>(
        appConfigurationService
            .getSupportedLanguages()
            .filter(l => l.domain !== currentDomain)
    );

    function getUrlForLanguage(language: SupportedLanguage): string {
        const curPathName = DOMPurify.sanitize(window.location.pathname);
        const currentPathWithoutBase =
            curPathName.indexOf(currentRoot) === 0
                ? curPathName.substring(currentRoot.length)
                : '';
        const url = `${window.location.protocol}//${language.domain}${window
            .location.port && ':' + window.location.port}${
            language.root
        }${currentPathWithoutBase}`;
        return url;
    }

    const languageList = () => {
        if (
            appConfigurationService.getAppConfiguration().environment !==
            'fallback'
        ) {
            return languages.map((language: SupportedLanguage) => (
                <li key={language.domain}>
                    <Link
                        href={sanitizeUrl(getUrlForLanguage(language))}
                        className="language-option"
                        tabIndex={props.tabIndex}
                    >
                        {language.name}
                    </Link>
                </li>
            ));
        } else {
            return appConfigurationService
                .getLanguageOverrides()
                .map((language: SupportedLanguageOverride) => (
                    <li key={`nodomain-${language.languageRegionCode}`}>
                        <button
                            className="language-option"
                            onClick={() => {
                                (window as any).languageOverride(
                                    language.languageName,
                                    language.langScript,
                                    language.languageRegionCode,
                                    language.countryCode,
                                    language.threeLetterCountryCode
                                );
                                window.location.reload();
                            }}
                        >
                            {language.languageName}
                        </button>
                    </li>
                ));
        }
    };

    return (
        <div className="language-toggle-container">
            <button
                tabIndex={props.tabIndex}
                className={`language-toggle ${props.className || ''}`}
                onClick={() => {
                    setPanelIsOpen(!panelIsOpen);
                }}
            >
                <img src={languageGlobe} alt="" />
                <span className="toggle-text">{currentLanguage}</span>
            </button>
            {panelIsOpen && (
                <div className="language-panel">
                    <ul>
                        <li key={currentDomain}>
                            <span className="language-option">
                                {currentLanguage}
                            </span>
                        </li>
                        {languageList()}
                    </ul>
                </div>
            )}
        </div>
    );
};
