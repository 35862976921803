import { ELIGIBLE_MODEL_NAMES, FM_ACTION_ITEMS_TYPE } from '../../../constants';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import RecallsService from '../../../services/recalls-service/recalls-service';
import ServiceHandler from '../../../services/service-handler';
import { SyncMapTile } from '../../../services/sync-service/sync-map-service';
import VhaService from '../../../services/vha-service/vha-service';
import YfaService from '../../../services/yfa-service/yfa-service';
import MMOTAStatusService from '../../../services/mmota-status-service/mmota-status-service';
import { DSL_MMOTA_STATUS } from '../../vehicle-checklist/vehicle-checklist-mmota/mmota-util';

export class FMActionItemsUtil {
    private static readonly EU_REGION = 'eu';
    static getRecallsAndCsp = (
        vehicleAttributes: VehicleAttributes,
        newCarouselState: Map<string, any>,
        setCarouselstate: React.Dispatch<React.SetStateAction<Map<string, any>>>
    ) => {
        new RecallsService()
            .request(vehicleAttributes.vin!)
            .then(response => {
                if (response?.recallsCount !== 0) {
                    newCarouselState.set(
                        FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP,
                        response
                    );
                }
            })
            .catch(() => {
                console.log('RECALLS service failed in fm-action-items');
            })
            .finally(() => {
                if (
                    newCarouselState.get(
                        FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP
                    ) != null
                ) {
                    setCarouselstate(prevState =>
                        new Map(prevState).set(
                            FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP,
                            newCarouselState.get(
                                FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP
                            )
                        )
                    );
                }
            });
    };
    static getRecallsAndCspYmm = (
        vehicleAttributes: VehicleAttributes,
        newCarouselState: Map<string, any>,
        setCarouselstate: React.Dispatch<React.SetStateAction<Map<string, any>>>
    ) => {
        new RecallsService()
            .requestYmm(
                vehicleAttributes.seoKey || vehicleAttributes.model,
                vehicleAttributes.year.toString()
            )
            .then(response => {
                if (response && response.recalls.length > 0) {
                    newCarouselState.set(
                        FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP,
                        response
                    );
                }
            })
            .catch(error => {
                console.error('Error fetching recall data:', error);
            })
            .finally(() => {
                if (
                    newCarouselState.get(
                        FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP
                    ) != null
                ) {
                    setCarouselstate(prevState =>
                        new Map(prevState).set(
                            FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP,
                            newCarouselState.get(
                                FM_ACTION_ITEMS_TYPE.RECALLS_AND_CSP
                            )
                        )
                    );
                }
            });
    };
    static getVhaService = (
        vehicleAttributes: VehicleAttributes,
        newCarouselState: Map<any, any>,
        setCarouselstate: React.Dispatch<React.SetStateAction<Map<string, any>>>
    ) => {
        new VhaService()
            .request(vehicleAttributes.vin!)
            .then(response => {
                if (
                    response &&
                    response.vhaAlertResponseList &&
                    response.vhaAlertResponseList.length > 0 &&
                    response.vhaAlertResponseList[0].activeAlerts &&
                    response.vhaAlertResponseList[0].activeAlerts.length > 0
                ) {
                    newCarouselState.set(FM_ACTION_ITEMS_TYPE.VHA, response);
                }
            })
            .catch(() => {
                console.log('VHA service failed in fm-action-items');
            })
            .finally(() => {
                if (newCarouselState.get(FM_ACTION_ITEMS_TYPE.VHA) != null)
                    setCarouselstate(prevState =>
                        new Map(prevState).set(
                            FM_ACTION_ITEMS_TYPE.VHA,
                            newCarouselState.get(FM_ACTION_ITEMS_TYPE.VHA)
                        )
                    );
            });
    };
    static getYfaService = (
        vehicleAttributes: VehicleAttributes,
        newCarouselState: Map<any, any>,
        setCarouselstate: React.Dispatch<React.SetStateAction<Map<string, any>>>
    ) => {
        if (
            vehicleAttributes?.engineType === 'BEV' &&
            ELIGIBLE_MODEL_NAMES.includes(
                vehicleAttributes?.model?.toLocaleLowerCase()
            )
        ) {
            new YfaService()
                .isEligible(vehicleAttributes.vin!)
                .then(response => {
                    if (response?.eligible) {
                        newCarouselState.set(
                            FM_ACTION_ITEMS_TYPE.YFA,
                            response.eligible
                        );
                    }
                })
                .catch(() => {
                    console.log('YFA service failed in fm-action-items');
                })
                .finally(() => {
                    if (newCarouselState.get(FM_ACTION_ITEMS_TYPE.YFA))
                        setCarouselstate(prevState =>
                            new Map(prevState).set(
                                FM_ACTION_ITEMS_TYPE.YFA,
                                newCarouselState.get(FM_ACTION_ITEMS_TYPE.YFA)
                            )
                        );
                });
        }
    };
    static getSyncMapService = (
        syncMapService: any,
        region: string,
        vehicleAttributes: VehicleAttributes,
        newCarouselState: Map<any, any>,
        setCarouselstate: React.Dispatch<React.SetStateAction<Map<string, any>>>
    ) => {
        syncMapService
            .request(vehicleAttributes.vin!)
            .then((response: SyncMapTile) => {
                if (
                    response &&
                    response.syncTile?.syncUpdateAvailable &&
                    region === this.EU_REGION
                ) {
                    newCarouselState.set(
                        FM_ACTION_ITEMS_TYPE.SYNC_MAP,
                        response
                    );
                }
            })
            .catch(() => {
                console.log('SyncMap service failed in fm-action-items');
            })
            .finally(() => {
                if (
                    newCarouselState.get(FM_ACTION_ITEMS_TYPE.SYNC_MAP) != null
                ) {
                    setCarouselstate(prevState =>
                        new Map(prevState).set(
                            FM_ACTION_ITEMS_TYPE.SYNC_MAP,
                            newCarouselState.get(FM_ACTION_ITEMS_TYPE.SYNC_MAP)
                        )
                    );
                }
            });
    };
    static requestSyncMapUpdates = (
        syncMapService: any,
        region: string,
        vehicleAttributes: VehicleAttributes,
        newCarouselState: Map<any, any>,
        setCarouselstate: React.Dispatch<React.SetStateAction<Map<string, any>>>
    ) => {
        syncMapService
            .requestSyncMapUpdates(vehicleAttributes.vin)
            .then(
                (response: {
                    syncUpdateResponse: {
                        generation: string;
                        hasUpdateAvailable: boolean;
                    };
                }) => {
                    const isSyncGeneration =
                        Number(response?.syncUpdateResponse?.generation) < 4;
                    const hasUpdate =
                        response?.syncUpdateResponse?.hasUpdateAvailable;
                    if (response && isSyncGeneration && hasUpdate) {
                        newCarouselState.set(
                            FM_ACTION_ITEMS_TYPE.SYNC,
                            response
                        );
                    }
                }
            )
            .catch(() => {
                console.log(
                    'Sync firmaware update service failed in fm-action-items'
                );
            })
            .finally(() => {
                if (newCarouselState.get(FM_ACTION_ITEMS_TYPE.SYNC) != null) {
                    setCarouselstate(prevState =>
                        new Map(prevState).set(
                            FM_ACTION_ITEMS_TYPE.SYNC,
                            newCarouselState.get(FM_ACTION_ITEMS_TYPE.SYNC)
                        )
                    );
                }
            });
    };
    static getMMOTAStatusService = (
        vehicleAttributes: VehicleAttributes,
        newCarouselState: Map<any, any>,
        setCarouselstate: React.Dispatch<React.SetStateAction<Map<string, any>>>
    ) => {
        new MMOTAStatusService()
            .getStatus(vehicleAttributes.vin!)
            .then((response: any) => {
                const isUpToDate =
                    response?.summary?.alertType?.toLowerCase() ===
                    DSL_MMOTA_STATUS.upToDate.toLocaleLowerCase();
                const isErrorDescription = !!response?.error;
                const isNoQualifedalert =
                    response?.status?.errorDescription?.toLowerCase() ===
                    DSL_MMOTA_STATUS?.upToDate?.toLocaleLowerCase();
                if (
                    !(
                        response == null ||
                        isUpToDate ||
                        isErrorDescription ||
                        isNoQualifedalert
                    )
                ) {
                    newCarouselState.set(
                        FM_ACTION_ITEMS_TYPE.SOFTWARE_UPDATE,
                        response
                    );
                }
            })
            .catch(() => {
                console.log(
                    'MMOTAStatusService failed, continuing with others.'
                );
            })
            .finally(() => {
                if (
                    newCarouselState.get(
                        FM_ACTION_ITEMS_TYPE.SOFTWARE_UPDATE
                    ) != null
                ) {
                    setCarouselstate(prevState =>
                        new Map(prevState).set(
                            FM_ACTION_ITEMS_TYPE.SOFTWARE_UPDATE,
                            newCarouselState.get(
                                FM_ACTION_ITEMS_TYPE.SOFTWARE_UPDATE
                            )
                        )
                    );
                }
            });
    };
    static getActivePrognosticsV2 = (
        syncMapService: any,
        vehicleAttributes: VehicleAttributes,
        newCarouselState: Map<any, any>,
        setCarouselstate: React.Dispatch<React.SetStateAction<Map<string, any>>>
    ) => {
        ServiceHandler.VehiclePrognosticsService.getActivePrognosticsV2(
            vehicleAttributes.vin!
        )
            .then(result => {
                if (result?.prognosticsData) {
                    const prognosticsData = result?.prognosticsData;
                    const tempPrognostics = {
                        oilLifeData: null,
                        scheduleMaintenanceData: null,
                    };
                    prognosticsData?.forEach((reminder: any) => {
                        if (reminder.featureType === 'OL') {
                            tempPrognostics.oilLifeData = reminder;
                        } else if (reminder.featureType === 'SM') {
                            tempPrognostics.scheduleMaintenanceData = reminder;
                        }
                    });
                    newCarouselState.set(
                        FM_ACTION_ITEMS_TYPE.SERVICE_REMINDER,
                        tempPrognostics
                    );
                }
            })
            .catch(() => {
                console.log(
                    'Prognostics service failed, continuing with others.'
                );
            })
            .finally(() => {
                if (
                    newCarouselState.get(
                        FM_ACTION_ITEMS_TYPE.SERVICE_REMINDER
                    ) != null
                ) {
                    setCarouselstate(prevState =>
                        new Map(prevState).set(
                            FM_ACTION_ITEMS_TYPE.SERVICE_REMINDER,
                            newCarouselState.get(
                                FM_ACTION_ITEMS_TYPE.SERVICE_REMINDER
                            )
                        )
                    );
                }
            });
    };
}
