import React, { ReactNode, useEffect, useRef, useState } from 'react';

import VINSelector from './vin-selector/vin-selector';
import YMMSelector from './ymm-selector/ymm-selector';

import './vehicle-selector-fds.scss';
import '../smash-vehicle-selector-fds/smash-vehicle-selector-fds.scss';
import FMALogin, {
    RedirectData,
} from '../../../support/components/FMALogin/FMALogin';
import { VehicleYmmGroup } from '../../../models/vehicle-ymm';
import FDSSegmentedControl from '../smash-vehicle-selector-fds/segmentedControl';
import { SyncMapsConsolidatedResponse } from '../../../views/sync-maps-updates-view/hooks/use-syncs-maps-status-updates';
import { ActivityIndicator } from '../../common/activity-indicator/activity-indicator';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import { findByAlias } from '../../../routes';

export interface VehicleSelectorLabels {
    yearDropdown: string;
    modelDropdown: string;
    submit: string;
    disclaimer: string;
    header: string;
    vinPlaceholder: string;
    vinHeader: string;
    dividerText?: string;
    vehicleSelectorModelSubtitle?: string;
    loginSwitch?: string;
    loggedInSwitch?: string;
    vehicleSwitch?: string;
    orSeparator?: string;
}

export interface Props {
    callbackMethod?: (
        syncMapsConsolidatedResponse: SyncMapsConsolidatedResponse
    ) => void;

    labels: VehicleSelectorLabels;
    tooltip?: ReactNode;
    handleDropDownChange?: (year: number, model: string) => void;
    handleVinSubmit?: (vin: string) => void;
    vehicleDropdownData?: VehicleYmmGroup[];
    handleYearModelSubmit?: (model: string, selectedYear: number) => void;
    redirectData?: RedirectData;
    eventName?: string;
}

export const VehicleSelectorFds = (props: Props) => {
    const loginRef = useRef<HTMLButtonElement>(null);
    const { handleDropDownChange, handleVinSubmit } = props;
    const handleYearModelSubmit = props.handleYearModelSubmit;
    const [loginLabel, setLoginLabel] = useState<string>(
        props.labels.loginSwitch || ''
    );
    const [authenticationState] = useAuthentication();
    const [selectedFmaVin, setSelectedFmaVin] = useState<string>('');
    useEffect(() => {
        if (authenticationState === AuthenticationState.Authenticated) {
            if (props.labels.loggedInSwitch) {
                setLoginLabel(props.labels.loggedInSwitch);
            }
            if (loginRef && loginRef.current) {
                loginRef.current.click();
            }
        }
    }, [authenticationState]);

    const vinYmmLoginToggle = () => {
        if (
            props.redirectData?.id === 'owner-manual' ||
            props.redirectData?.id === 'warranty-information'
        ) {
            const fdsElement = document.querySelector(
                '.vehicle-selector-fds-container'
            ) as HTMLElement;
            return new FDSSegmentedControl(fdsElement);
        }
    };
    useEffect(() => {
        vinYmmLoginToggle();
    });
    const OwnerManualSwitch = (props: Props, switchId: string) => {
        const { labels, redirectData, eventName } = props;
        return (
            <div className="vehicle-selector-fds-container">
                <div className=" fds-segmented-control fds-color__bg--light">
                    <div className="fds-segmented-control__button-list-flex-container">
                        <div
                            className="fds-segmented-control__button-list-wrapper"
                            aria-label=""
                        >
                            <div className="fds-segmented-control__button-list">
                                <button
                                    className="fds-segmented-control__button"
                                    aria-pressed="true"
                                    aria-controls="FDSSegmentedControl_2Buttons_NoIcon_Panel1"
                                    id="FDSSegmentedControl_2Buttons_NoIcon_Button1"
                                >
                                    <span className="fds-segmented-control__button-text">
                                        {labels.vehicleSwitch}
                                    </span>
                                </button>
                                <button
                                    ref={loginRef}
                                    className="fds-segmented-control__button"
                                    aria-pressed="false"
                                    aria-controls="FDSSegmentedControl_2Buttons_NoIcon_Panel2"
                                    id="FDSSegmentedControl_2Buttons_NoIcon_Button2"
                                >
                                    <span className="fds-segmented-control__button-text">
                                        {authenticationState ===
                                        AuthenticationState.Pending ? (
                                            <ActivityIndicator radius="10" />
                                        ) : (
                                            loginLabel
                                        )}
                                    </span>
                                </button>
                            </div>
                            <span className="fds-segmented-control__slider">
                                <span className="fds-segmented-control__slider-text">
                                    {labels.vehicleSwitch}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div className="fds-segmented-control__panels">
                        <div
                            id="FDSSegmentedControl_2Buttons_NoIcon_Panel1"
                            className="fds-segmented-control__panel"
                            aria-labelledby="FDSSegmentedControl_2Buttons_NoIcon_Button1"
                            tabIndex={0}
                        >
                            <div className="fds-segmented-control__panel-content">
                                {props.redirectData && (
                                    <div className="input-container">
                                        {switchId === 'warranty' && (
                                            <VINSelector
                                                vinHeaderLabel={
                                                    labels.vinHeader
                                                }
                                                vinPlaceholderLabel={
                                                    labels.vinPlaceholder
                                                }
                                                submitLabel={labels.submit}
                                                tooltip={props.tooltip}
                                                isFmaLogin={!!redirectData}
                                                redirectData={{
                                                    path: findByAlias(
                                                        'WarrantyInformationViewRoute'
                                                    ),
                                                    id: 'warranty-information',
                                                }}
                                                eventName={eventName}
                                                handleVinSubmit={
                                                    handleVinSubmit
                                                }
                                            />
                                        )}
                                        {switchId === 'owners-manual' && (
                                            <VINSelector
                                                vinHeaderLabel={
                                                    labels.vinHeader
                                                }
                                                vinPlaceholderLabel={
                                                    labels.vinPlaceholder
                                                }
                                                submitLabel={labels.submit}
                                                tooltip={props.tooltip}
                                                isFmaLogin={!!redirectData}
                                                redirectData={{
                                                    path: findByAlias(
                                                        'OwnerManualWithVin'
                                                    ),
                                                    id: 'owner-manual',
                                                }}
                                                eventName={eventName}
                                            />
                                        )}
                                        <span
                                            className={
                                                redirectData
                                                    ? 'or'
                                                    : 'or mobile-only'
                                            }
                                        >
                                            {labels.dividerText}
                                        </span>
                                        {labels && (
                                            <YMMSelector
                                                {...props}
                                                ymmHeaderLabel={
                                                    labels.vehicleSelectorModelSubtitle ||
                                                    ''
                                                }
                                                yearDropdownLabel={
                                                    labels.yearDropdown
                                                }
                                                modelDropdownLabel={
                                                    labels.modelDropdown
                                                }
                                                submitLabel={labels.submit}
                                                eventName={eventName}
                                                handleDropDownChange={
                                                    handleDropDownChange
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            role="group"
                            id="FDSSegmentedControl_2Buttons_NoIcon_Panel2"
                            className="fds-segmented-control__panel"
                            aria-labelledby="FDSSegmentedControl_2Buttons_NoIcon_Button2"
                            tabIndex={0}
                        >
                            <div className="fds-segmented-control__panel-content">
                                {props.redirectData && (
                                    <div className="input-container">
                                        {switchId === 'warranty' && (
                                            <FMALogin
                                                redirectData={{
                                                    path: findByAlias(
                                                        'WarrantyInformationPage'
                                                    ),
                                                    id: 'warranty-information',
                                                }}
                                                eventName={eventName}
                                                handleVinChange={
                                                    handleVinSubmit
                                                }
                                            />
                                        )}
                                        {switchId === 'owners-manual' && (
                                            <FMALogin
                                                redirectData={{
                                                    path: findByAlias(
                                                        'OwnerManualWithVin'
                                                    ),
                                                    id: 'owner-manual',
                                                }}
                                                eventName={eventName}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const renderComponentsBasedOfId = (props: Props) => {
        const { labels, redirectData } = props;
        switch (redirectData?.id) {
            case 'recalls':
            case 'sync-maps':
                return (
                    <>
                        <div className="input-container">
                            <VINSelector
                                vinHeaderLabel={labels.vinHeader}
                                vinPlaceholderLabel={labels.vinPlaceholder}
                                submitLabel={labels.submit}
                                tooltip={props.tooltip}
                                isFmaLogin={!!redirectData}
                                redirectData={props.redirectData}
                                callbackMethod={props.callbackMethod}
                                handleVinSubmit={handleVinSubmit}
                                selectedFmaVin={selectedFmaVin}
                            />
                            <span
                                className={
                                    redirectData ? 'or' : 'or mobile-only'
                                }
                            >
                                {labels.dividerText}
                            </span>

                            <FMALogin
                                redirectData={redirectData}
                                {...(redirectData.id === 'sync-maps'
                                    ? { aemPath: 'sync' }
                                    : {})}
                                handleVinChange={handleVinSubmit}
                                selectedVinCallback={setSelectedFmaVin}
                            />
                        </div>
                    </>
                );
            case 'owner-manual':
                return <>{OwnerManualSwitch(props, 'owners-manual')}</>;
            case 'warranty-information':
                return <>{OwnerManualSwitch(props, 'warranty')}</>;
            default:
                return (
                    <>
                        <div className="input-container">
                            <VINSelector
                                vinHeaderLabel={labels.vinHeader}
                                vinPlaceholderLabel={labels.vinPlaceholder}
                                submitLabel={labels.submit}
                                tooltip={props.tooltip}
                                isFmaLogin={!!redirectData}
                                redirectData={props.redirectData}
                                handleVinSubmit={handleVinSubmit}
                            />
                            <span
                                className={
                                    redirectData ? 'or' : 'or mobile-only'
                                }
                            >
                                {redirectData?.id == 'TSB'
                                    ? labels.orSeparator
                                    : labels.dividerText}
                            </span>
                            <YMMSelector
                                {...props}
                                ymmHeaderLabel={labels.header}
                                yearDropdownLabel={labels.yearDropdown}
                                modelDropdownLabel={labels.modelDropdown}
                                submitLabel={labels.submit}
                                handleDropDownChange={handleDropDownChange}
                                handleYearModelSubmit={handleYearModelSubmit}
                            />
                        </div>
                    </>
                );
        }
    };
    return (
        <div className="vehicle-selector-fds">
            {renderComponentsBasedOfId(props)}
            {props.labels.disclaimer && (
                <span className="disclaimer">{props.labels.disclaimer}</span>
            )}
        </div>
    );
};
