import React from 'react';

interface Props {
    largeDesktopImagePath: string;
    desktopImagePath: string;
    tabletImagePath: string;
    mobileImagePath: string;
    imageAltText: string;
    testId?: string;
    fetchPriority?: 'high' | 'low' | 'auto';
    largeDesktopImageWidth?: string;
    largeDesktopImageHeight?: string;
    desktopImageWidth?: string;
    desktopImageHeight?: string;
    tabletImageWidth?: string;
    tabletImageHeight?: string;
    mobileImageWidth?: string;
    mobileImageHeight?: string;
    className?: string;
}

export const Picture = (props: Props) => {
    return (
        <picture>
            <source
                srcSet={
                    process.env.REACT_APP_AEM_BASE_URL +
                    props.largeDesktopImagePath
                }
                media="(min-width: 1200px)"
                {...(props.className && { className: props.className })}
                {...(props.largeDesktopImageWidth && {
                    width: props.largeDesktopImageWidth,
                })}
                {...(props.largeDesktopImageHeight && {
                    height: props.largeDesktopImageHeight,
                })}
            />
            <source
                srcSet={
                    process.env.REACT_APP_AEM_BASE_URL + props.desktopImagePath
                }
                media="(min-width: 993px) and (max-width:1199px )"
                {...(props.className && { className: props.className })}
                {...(props.desktopImageWidth && {
                    width: props.desktopImageWidth,
                })}
                {...(props.desktopImageHeight && {
                    height: props.desktopImageHeight,
                })}
            />
            <source
                srcSet={
                    process.env.REACT_APP_AEM_BASE_URL + props.tabletImagePath
                }
                media="(min-width: 768px) and (max-width:992px )"
                {...(props.className && { className: props.className })}
                {...(props.tabletImageWidth && {
                    width: props.tabletImageWidth,
                })}
                {...(props.tabletImageHeight && {
                    height: props.tabletImageHeight,
                })}
            />
            <img
                fetchpriority={props.fetchPriority || 'high'}
                data-testid={props.testId}
                alt={props.imageAltText}
                src={process.env.REACT_APP_AEM_BASE_URL + props.mobileImagePath}
                {...(props.className && { className: props.className })}
                {...(props.mobileImageWidth && {
                    width: props.mobileImageWidth,
                })}
                {...(props.mobileImageHeight && {
                    height: props.mobileImageHeight,
                })}
            />
        </picture>
    );
};
