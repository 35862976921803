import React from 'react';
import { UpcomingEvent } from './components/upcoming-event';
import './live-stream.scss';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import { useAnalytics } from '../../../hooks/use-analytics';
import { useShortcodeProvider } from '../../../hooks/use-shortcode-provider';
import { getVehicleDataSelector } from '../../../services/content-service/content-service-util';
import useSeoConfig from '../../../hooks/use-seo-config';
import { useDfyLivestream } from './hooks/use-dyf-livestream-view';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { useLiveStreamViewContent } from '../../../views/live-stream-view/hook/use-live-stream-view';
import { getLiveStreamDetails } from './live-stream-builder';
import { DyfOnboardingScheduleAemContent } from '../gvt/gvt-interfaces';
import UpcomingLivestream from '../gvt/upcoming-livestream/upcoming-livestream';
import { FMCardFiftyFifty } from '@own/fds-react';
import { VideoTiles } from '../onboarding-global-components/video-tiles/video-tiles';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { ViewportUtil } from '../../utils/viewport-util/viewport-util';

interface Props {
    vehicleAttributes: VehicleAttributes;
    shouldShowUpcomingLivestreams: boolean;
}

export const LiveStream = (props: Props) => {
    const dyfComponentName = {
        dyfComponentName: 'livestream',
        ymm: useShortcodeProvider(props.vehicleAttributes)[0],
    };
    const modelNotSupportedContent = useLiveStreamViewContent();
    const smashCardGvt = useDfyLivestream(
        'vehicle-data',
        getVehicleDataSelector('smash-card', props.vehicleAttributes),
        'article',
        true
    );
    const dyfRecordedLivestream = useDfyLivestream(
        'vehicle-data',
        getVehicleDataSelector(
            'dyf-recorded-live-stream',
            props.vehicleAttributes
        ),
        'article',
        true
    );
    const [gvtSchedulingContent] = useExperienceContent<
        DyfOnboardingScheduleAemContent
    >(
        'vehicles',
        'dyf-onboarding-scheduling',
        'dyf_onboarding_sched',
        undefined,
        false
    );

    useAnalytics(['dyf-generic'], undefined, dyfComponentName);
    useSeoConfig('livestream', 'dyf', [
        {
            fieldName: 'ymm',
            fieldValue: `${props.vehicleAttributes.year} ${props.vehicleAttributes.make} ${props.vehicleAttributes.model}`,
        },
    ]);

    const { isMobileView } = ViewportUtil();
    const [fireEvents] = useAnalytics();
    const eventClick = async () => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            smashCardGvt?.ctaPath || ''
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else {
            fireEvents('smash-5050-click-event', undefined, {});
        }
    };
    return (
        gvtSchedulingContent && (
            <div className="livestream-wrapper">
                {props.shouldShowUpcomingLivestreams && (
                    <UpcomingEvent
                        livestream={getLiveStreamDetails(
                            props.vehicleAttributes.model
                        )}
                    />
                )}
                <div className="livestream-content-wrapper">
                    <UpcomingLivestream
                        vehicleAttributes={props.vehicleAttributes}
                        modelNotSupportedContent={modelNotSupportedContent}
                        shouldShowUpcomingLivestreams={
                            props.shouldShowUpcomingLivestreams
                        }
                    />
                    {dyfRecordedLivestream && (
                        <FMCardFiftyFifty
                            headline={dyfRecordedLivestream?.title}
                            description={dyfRecordedLivestream?.description}
                            media={{
                                src: `${process.env.REACT_APP_AEM_BASE_URL}${
                                    isMobileView
                                        ? dyfRecordedLivestream?.mobileImagePath
                                        : dyfRecordedLivestream?.desktopImagePath
                                }`,
                                alt: dyfRecordedLivestream?.imageAltText,
                            }}
                            button={{
                                type: 'secondary',
                                label: dyfRecordedLivestream?.ctaLabel,
                                href: dyfRecordedLivestream?.ctaPath,
                                disabled: false,
                                target: dyfRecordedLivestream?.targetBlank
                                    ? '_blank'
                                    : '_self',
                                ariaLabel: dyfRecordedLivestream?.ctaAriaLabel,
                            }}
                            mediaSide={'right'}
                        />
                    )}
                    {smashCardGvt && (
                        <div className="smash-card-gvt">
                            <FMCardFiftyFifty
                                headline={smashCardGvt?.title}
                                description={smashCardGvt?.description}
                                media={{
                                    src: `${
                                        process.env.REACT_APP_AEM_BASE_URL
                                    }${
                                        isMobileView
                                            ? smashCardGvt?.mobileImagePath
                                            : smashCardGvt?.desktopImagePath
                                    }`,
                                    alt: smashCardGvt?.imageAltText,
                                }}
                                button={{
                                    type: 'secondary',
                                    label: smashCardGvt?.ctaLabel,
                                    href: smashCardGvt?.ctaPath,
                                    disabled: false,
                                    onClick: () => eventClick(),
                                    target: smashCardGvt?.targetBlank
                                        ? '_blank'
                                        : '_self',
                                    ariaLabel: smashCardGvt?.ctaAriaLabel,
                                }}
                                mediaSide={'left'}
                            />
                        </div>
                    )}
                </div>
                <VideoTiles
                    vehicleAttributes={props.vehicleAttributes}
                    seoKey={props.vehicleAttributes.seoKey}
                    origin={'livestream'}
                />
            </div>
        )
    );
};
