import { useState, useEffect } from 'react';
import { useContent } from '../../../hooks/use-server-content';

export interface DyfVehicleSelectorContentProps {
    bannerImageDesktop: string;
    bannerImageMobile: string;
    bannerImageText: string;
    bannerImageAltText: string;
    unauthHeadline: string;
    unauthSubHeadline: string;
    authHeadline: string;
    authSubHeadline: string;
    addVehicleButtonText: string;
    addVehicleButtonAriaLabel: string;
    signInButtonText: string;
    signInButtonAriaLabel: string;
    vehicleSelectorTitle: string;
    vehicleSelectorDescription: string;
    vinLabelText: string;
    orText: string;
    yearLabelText: string;
    modelLabelText: string;
    whereDoIFindVinText: string;
    whereDoIFindVinLink: string;
    searchButtonText: string;
    searchButtonAriaLabel: string;
    vinInputErrorMessage: string;
}
type ContentState = {
    loading: boolean;
    data: DyfVehicleSelectorContentProps | null;
};

export const useDyfVehicleSelectorContent = (): ContentState => {
    const [content, getValueByName] = useContent('dyf', `dyf-vehicle-selector`);

    const [state, setState] = useState<ContentState>({
        loading: true,
        data: null,
    });

    useEffect(() => {
        if (content) {
            const contentFragment: any = {};
            content?.elements.forEach(element => {
                contentFragment[`${element.name}`] = getValueByName(
                    `${element.name}`
                );
            });

            setState({
                loading: false,
                data: contentFragment as DyfVehicleSelectorContentProps,
            });
        }
    }, [content]);

    return state;
};
