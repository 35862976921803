import { useEffect } from 'react';
import ServerSideService from '../services/server-side-service/server-side-service';

const useFooterDisplay = (dependencies: any[] = [], timeout = 1000) => {
    useEffect(() => {
        if (ServerSideService.isClientSide()) {
            setTimeout(() => {
                const footer = document.getElementById(
                    'owner-syndicated-footer'
                );

                if (footer) footer.style.display = 'block';
            }, timeout);
        }
    }, dependencies);
};

export default useFooterDisplay;
