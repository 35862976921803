import { SyncMapsCard, SyncMapStatus } from '../use-syncs-maps-status-updates';

export const getSyncCards = (
    syncMapContent: SyncMapStatus | null,
    syncOption: string,
    syncGeneration: string,
    desktopOrLarger: boolean
): SyncMapsCard[] | undefined => {
    const isMobileCardForDesktopOrLarger = (card: SyncMapsCard) =>
        desktopOrLarger ? !card.isMobileCard : card.isMobileCard;
    return (
        syncMapContent?.syncCards &&
        syncMapContent?.syncCards
            .filter(card => card.syncMapsCardType === syncOption)
            .filter(card =>
                card.syncMapsCardType === 'usb'
                    ? isMobileCardForDesktopOrLarger(card)
                    : card
            )
    );
};
