import React, { ReactNode } from 'react';
import { Carousel } from '../../../../common/carousel/carousel';
import { useBreakpoints } from '../../../../../hooks/use-breakpoints';
import { SimpleCard } from '../../../../../models/experiencefragments/category-view';

import './simple-cards.scss';
import { FMButton, FMCarousel } from '@own/fds-react';
import { CategoryOnClickCtaInfo } from '../../../../../services/shortcode-service/shortcode-service';
import { NewAnalyticsService } from '../../../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../../../hooks/use-analytics';

interface Props {
    className?: string;
    cards: SimpleCard[];
    role?: string;
    ariaLive?: string;
    ariaLabel?: string;
    paginationText?: string;
    leftButtonArialLabel?: string;
    rightButtonAriaLabel?: string;
    currentVersion?: boolean;
    categoryPath?: string;
    categoryType?: string;
}
export function SimpleCards(props: Props) {
    const { mobile, tabletOrLarger } = useBreakpoints();
    const [fireEvents] = useAnalytics();
    const eventClick = async (ctaUrl: string, ctaName: string) => {
        const categoryOnClickCtaInfo: CategoryOnClickCtaInfo = {
            ctaName: ctaName,
            ctaUrl: ctaUrl,
            categoryType: props.categoryType,
            categoryPath: props.categoryPath,
        };
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaUrl
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else {
            fireEvents('category-onclick-event', undefined, {
                categoryOnClickCtaInfo,
            });
        }
    };
    const renderSimpleCard = (card: SimpleCard): ReactNode => {
        return (
            <div className="simple-cards-mobile-card" key={card.plainTitle}>
                <img
                    src={`${process.env.REACT_APP_AEM_BASE_URL}${card.iconPath}`}
                    className="simple-cards-mobile-icon"
                    alt={card.iconAltText}
                    loading="lazy"
                />
                <h2 className="simple-cards-mobile-headline">{card.title}</h2>
                <div className="simple-cards-mobile-description">
                    {card.body}
                </div>
                {card.ctaPath && card.ctaLabel && !props.currentVersion && (
                    <FMButton
                        className={'simple-cards-optional-cta'}
                        type={'tertiary'}
                        label={card.ctaLabel}
                        ariaLabel={card.ctaAriaLabel}
                        href={card.ctaPath}
                        chevron={'right'}
                        target={card.openInNewTab ? '_blank' : '_self'}
                        onClick={() => {
                            if (
                                props.categoryType === 'subcategory' &&
                                card.ctaPath &&
                                card.ctaLabel
                            )
                                eventClick(card.ctaPath, card.ctaLabel);
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="simple-cards-wrapper">
            {mobile && props.currentVersion && (
                <Carousel<SimpleCard>
                    className="simple-cards-mobile"
                    items={props.cards}
                    render={renderSimpleCard}
                    paginationText={props.paginationText}
                    leftButtonArialLabel={props.leftButtonArialLabel}
                    rightButtonAriaLabel={props.rightButtonAriaLabel}
                    role={props.role}
                    ariaLive={props.ariaLive}
                    ariaLabel={props.ariaLabel}
                />
            )}
            {mobile && !props.currentVersion && (
                <div className={'simple-cards-mobile'}>
                    <FMCarousel
                        instanceName="simple-cards-carousel"
                        items={props.cards}
                        render={renderSimpleCard}
                    ></FMCarousel>
                </div>
            )}
            {tabletOrLarger && (
                <div className="simple-cards">
                    {props.cards && props.cards.map(renderSimpleCard)}
                </div>
            )}
        </div>
    );
}
