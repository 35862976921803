import { DSL_BASE_URL, DSL_API_PATH } from '../../constants';
import Axios, { AxiosResponse } from 'axios';
import { HttpsProxyAgent } from 'https-proxy-agent';
import {
    ProfileWithVehiclesResponse,
    VehicleDetail,
    SwapTokenResponse,
} from '../../models/profile-with-vehicles';
import HttpService from '../http-service/http-service';
import ServiceHandler from '../service-handler';
import { CustomerIdResponse } from '../../models/consumer-id';
import { BrandUtil } from '../../components/utils/brand-util/brand-util';
import AuthenticationService from '../authentication-service/authentication-service';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

const axios = Axios.create({
    proxy: false,
    httpAgent: proxyAgent,
});

const STORAGE_KEY_USER_PREFERENCES = 'userPreferences';

export default class ProfileService {
    public async request(): Promise<ProfileWithVehiclesResponse> {
        const url = `${DSL_BASE_URL + DSL_API_PATH.PROFILE_WITH_VEHICLES_LITE}`;
        const headers = HttpService.getAppIdAndAuthTokenRequestHeaders();
        const {
            current3LetterCountryCode,
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        const params = {
            country: current3LetterCountryCode.toUpperCase(),
            locale: currentLanguageRegionCode,
        };
        return HttpService.get<any>(
            url,
            true,
            {
                params,
                headers: headers,
                proxy: false,
                httpAgent: proxyAgent,
            },
            180000
        ).then(this.transformResponse);
    }

    private transformResponse(
        response: AxiosResponse<any>
    ): ProfileWithVehiclesResponse {
        const { brandName } = new BrandUtil();
        const value = response.data.value as ProfileWithVehiclesResponse;
        if (value) {
            value.vehicles.forEach((vehicleDetail: VehicleDetail) => {
                if (vehicleDetail.make === 'F') {
                    vehicleDetail.make = 'Ford';
                } else if (vehicleDetail.make === 'L') {
                    vehicleDetail.make = 'Lincoln';
                } else if (vehicleDetail.make === 'M') {
                    vehicleDetail.make = 'Mercury';
                }
            });
            return {
                profile: value.profile,
                vehicles: value.vehicles.filter(
                    vehicle =>
                        vehicle.make.toLowerCase() === brandName.toLowerCase()
                ),
            };
        } else {
            throw new Error(
                response.data.error
                    ? response.data.error.message
                    : 'Invalid profile response'
            );
        }
    }

    public async addVehicle(vin: string, nickname: string): Promise<any> {
        const {
            currentLanguageRegionCode,
        } = ServiceHandler.AppConfigurationService;
        const url = DSL_BASE_URL + DSL_API_PATH.ADD_VEHICLE;
        return axios
            .post<any>(
                `${url}?nickname=${nickname}&locale=${currentLanguageRegionCode}`,
                null,
                {
                    headers: {
                        ...HttpService.getAppIdAndAuthTokenRequestHeaders(),
                        vin,
                    },
                }
            )
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    }

    public persistVehicleSelection(
        email: string | undefined,
        lastSelectedVin: string | undefined
    ): void {
        if (email && lastSelectedVin) {
            let updatedPreferences = JSON.stringify({
                [email]: { lastSelectedVin },
            });
            const userPreferences: any = localStorage.getItem(
                STORAGE_KEY_USER_PREFERENCES
            );
            if (userPreferences) {
                const userPreferencesObj = JSON.parse(userPreferences);
                if (userPreferences[email])
                    userPreferences[email].lastSelectedVin = lastSelectedVin;
                else userPreferencesObj[email] = { lastSelectedVin };
                updatedPreferences = JSON.stringify(userPreferencesObj);
            }
            localStorage.setItem(
                STORAGE_KEY_USER_PREFERENCES,
                updatedPreferences
            );
        }
    }

    public getUserPreferences(email: string | undefined): any | undefined {
        if (email) {
            const userPreferences = localStorage.getItem(
                STORAGE_KEY_USER_PREFERENCES
            );
            return userPreferences && JSON.parse(userPreferences)[email];
        }
    }
    public async retrieveCustomerId(): Promise<CustomerIdResponse> {
        const {
            current3LetterCountryCode,
        } = ServiceHandler.AppConfigurationService;
        return HttpService.get<any>(
            DSL_BASE_URL + DSL_API_PATH.CONSUMER_ID,
            true,
            {
                params: {
                    countryCode: current3LetterCountryCode.toUpperCase(),
                },
                headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            },
            180000
        ).then(response => {
            return response.data;
        });
    }
    public swapToken(appText: string): Promise<SwapTokenResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.SWAP_TOKEN;
        const authService = new AuthenticationService();
        return HttpService.put<any>(
            url,
            {
                app_text: appText,
                refresh_token: authService.getCatBundle()?.refresh_token,
            },
            {
                headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            }
        ).then(response => {
            return response?.data;
        });
    }
}
