import { useAnalytics } from '../../../hooks/use-analytics';
import serverSideService from '../../../services/server-side-service/server-side-service';
import './quick-link.scss';
interface Link {
    link: string;
    title: string;
    category: string;
}

interface Props {
    links: Link[];
    category: string;
    vin: string;
}

export const SecondaryNavQuickLinks = (props: Props) => {
    const { links, category, vin } = props;
    const [fireEvents] = useAnalytics();

    const handleClick = (e: any, item: Link) => {
        e.preventDefault();
        let redirectUrl = item.link;
        if (item.title === 'Connected Services') {
            redirectUrl = redirectUrl.concat(`?vin=${vin}`);
        } else if (item.title === 'Feature Guides') {
            redirectUrl = redirectUrl.replace(':vin', vin);
        }
        if (serverSideService.isClientSide()) {
            window.location.href = redirectUrl;
        }
        fireEvents(
            'secondary-nav-category-link-onclick-event',
            undefined,
            {
                secondaryNavClickEvents: {
                    category: item.category,
                    title: item.title,
                },
            },
            false
        );
    };

    return (
        <div className="quick-link-container">
            <div className="quick-link-category">{category}</div>
            {links.map(item => {
                return (
                    <a
                        className="quick-link"
                        href={item.link}
                        onClick={e => handleClick(e, item)}
                    >
                        {item.title}
                    </a>
                );
            })}
        </div>
    );
};
