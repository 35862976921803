import React from 'react';

import { Link } from '../../../common';
import { BetaWrapper } from '../../../utils/beta-wrapper/beta-wrapper';
import './hero-tile.scss';
import { useAnalytics } from '../../../../hooks/use-analytics';
import { FdsChevron } from '../../../common/chevron/fds-chevron';
import RightArrow from '../../../../assets/lincoln-icons/arrowforward.svg';
import { BrandUtil } from '../../../utils/brand-util/brand-util';
export interface HeroTileProps {
    title: string;
    link: string;
    linkTarget?: string;
    imageUrl: string;
    eventName?: string;
    ariaLabel?: string;
}

export const HeroTile = (props: HeroTileProps) => {
    const [fireEvents] = useAnalytics();
    const { isLincoln } = new BrandUtil();
    return (
        <Link
            href={props.link}
            className="hero-tile"
            onClick={() => {
                if (props?.eventName) {
                    fireEvents(props.eventName, undefined, undefined, false);
                }
            }}
            target={props.linkTarget}
            aria-label={props.ariaLabel}
        >
            {props.imageUrl && (
                <img
                    className="tile-icon"
                    src={props.imageUrl}
                    alt=""
                    loading="lazy"
                    height={32}
                    width={32}
                />
            )}
            <div className="content">
                <BetaWrapper text={props.title} />
                {!props.imageUrl && !isLincoln && (
                    <FdsChevron type="unfilled" direction="right"></FdsChevron>
                )}
            </div>
            {isLincoln && (
                <div className="search-icon">
                    <img src={RightArrow} alt="" />
                </div>
            )}
        </Link>
    );
};
