import React, { useContext } from 'react';
import { HelpCardsProps } from '../../../../views/page-not-found-view/hooks/use-help-cards-content';
import { VehicleAttributes } from '../../../../models/vehicle-attributes';
import ServerContext from '../../../../contexts/serverContext';
import { ASTUTE } from '../../../../support/support-constants';
import { FMButton } from '@own/fds-react';
import { SearchContentProps } from '../../search-bar/hooks/use-search-content';
import './pre-imagine-escape-hatch.scss';
import PreImagineSearchBar from '../../search-bar/pre-imagine-search-bar/pre-imagine-search-bar';
import phone from '../../../../assets/phone-icon-blue.svg';
import dealer from '../../../../assets/dealer-pin-blue-updated.svg';
import repair from '../../../../assets/repair-car-blue.svg';
interface Props {
    vehicleAttributes?: VehicleAttributes;
    searchContent: SearchContentProps;
    helpCardsContent: HelpCardsProps;
}

export const PreImagineEscapeHatch = (props: Props) => {
    const serverContext = useContext(ServerContext);
    const hideSearchBar = ASTUTE.DOMAINS_TO_SKIP_BBT_SEARCH.includes(
        serverContext.domain
    );
    return (
        <>
            {props.searchContent && props.helpCardsContent && !hideSearchBar && (
                <div className="preimagine-escape-hatch" id="SH">
                    <PreImagineSearchBar searchContent={props.searchContent} />
                    <div className="escape-hatch-hatches">
                        <FMButton
                            type={'secondary'}
                            label={
                                <div className="escape-hatch-escape-button">
                                    {
                                        props.helpCardsContent
                                            .contactUsHelpCardTitle
                                    }
                                    <img
                                        loading="lazy"
                                        className="escape-hatch-escape-button-img"
                                        src={phone}
                                        aria-hidden={true}
                                        height={24}
                                        width={24}
                                        alt={''}
                                    />
                                </div>
                            }
                            ariaLabel={
                                props.helpCardsContent
                                    .contactUsHelpCarAriaLabel ||
                                'contact us button'
                            }
                            href={props.helpCardsContent.contactUsHelpCardURL}
                            theme="light"
                        />
                        <FMButton
                            type={'secondary'}
                            label={
                                <div className="escape-hatch-escape-button">
                                    {
                                        props.helpCardsContent
                                            .myFordVehicleHelpCardTitle
                                    }

                                    <img
                                        loading="lazy"
                                        className="escape-hatch-escape-button-img"
                                        src={repair}
                                        aria-hidden={true}
                                        height={24}
                                        width={24}
                                        alt={''}
                                    />
                                </div>
                            }
                            ariaLabel={
                                props.helpCardsContent
                                    .myFordVehicleHelpCardAriaLabel ||
                                'my ford vehicle button'
                            }
                            href={
                                props.helpCardsContent.myFordVehicleHelpCardURL
                            }
                            theme="light"
                        />

                        <FMButton
                            type={'secondary'}
                            label={
                                <div className="escape-hatch-escape-button">
                                    {
                                        props.helpCardsContent
                                            .findVehicleHelpCardTitle
                                    }
                                    <img
                                        loading="lazy"
                                        className="escape-hatch-escape-button-img"
                                        src={dealer}
                                        aria-hidden={true}
                                        width={24}
                                        height={24}
                                        alt={''}
                                    />
                                </div>
                            }
                            href={props.helpCardsContent.findVehicleHelpCardURL}
                            ariaLabel={
                                props.helpCardsContent
                                    .findVehicleHelpCardAriaLabel ||
                                'find vehicle button'
                            }
                            theme="light"
                        />
                    </div>
                </div>
            )}
        </>
    );
};
