import React from 'react';
import { KEYBOARD_KEYS } from '../../../constants';
import lincolnClose from '../../../assets/lincoln-icons/white-close.svg';
import close from '../../../assets/close.svg';

interface Props {
    tooltipHeader?: string;
    tooltipContent: string;
    customDismissTooltipAriaLabel?: string;
    nextFocus: (selector: string) => void;
    closeToolTipHandler: () => void;
    closeButtonRef: React.RefObject<HTMLButtonElement>;
    brand: string;
    tooltipOsb: { dismissTooltipAriaLabel: string };
}
export const TooltipPanel = (props: Props) => {
    return (
        <>
            <div>
                <div className="tooltip__header">
                    {props.tooltipHeader && (
                        <h2
                            className="tooltip__header-content"
                            id="tooltip__header-content"
                        >
                            {props.tooltipHeader}
                        </h2>
                    )}
                </div>
                <div
                    className="tooltip__inner-content"
                    id="tooltip-content-div"
                    tabIndex={0}
                    dangerouslySetInnerHTML={{
                        __html: props.tooltipContent,
                    }}
                    onKeyDown={event => {
                        if (event.key === KEYBOARD_KEYS.TAB) {
                            event.preventDefault();
                            props.nextFocus(`.tooltip__dismiss-button`);
                        }
                    }}
                />
                <button
                    className="tooltip__dismiss-button"
                    onClick={props.closeToolTipHandler}
                    onKeyDown={event => {
                        if (
                            event.key === KEYBOARD_KEYS.ENTER ||
                            event.key === KEYBOARD_KEYS.ESC
                        ) {
                            event.preventDefault();
                            props.closeToolTipHandler();
                        }
                        if (event.key === KEYBOARD_KEYS.TAB) {
                            event.preventDefault();
                            props.nextFocus(`.tooltip__inner-content`);
                        }
                    }}
                    ref={props.closeButtonRef}
                    aria-label={
                        props?.customDismissTooltipAriaLabel ||
                        props.tooltipOsb?.dismissTooltipAriaLabel
                    }
                >
                    <img
                        className="tooltip__dismiss-icon"
                        src={props.brand === 'lincoln' ? lincolnClose : close}
                        alt=""
                    />
                </button>
            </div>
        </>
    );
};
